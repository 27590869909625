import React from 'react';
import styled from 'styled-components';
import { clearfix } from '../style/mixin';
import theme from '../style/theme';

interface Props {
  width?: number;
  xs_padding?: string;
  sm_padding?: string;
  md_padding?: string;
  children?: React.ReactNode;
}
const Container = styled.div<{
  width?: number;
  xs_padding?: string;
  sm_padding?: string;
  md_padding?: string;
}>`
  position: relative;
  padding: 0 20px;
  max-width: ${props => (props.width ? props.width : 1064)}px;
  margin: 0 auto;
  ${clearfix}
  ${props =>
    props.md_padding &&
    `
  @media ${theme.md_max} {
    padding: ${props.md_padding};
  }`}
  ${props =>
    props.sm_padding &&
    `
  @media ${theme.sm_max} {
    padding: ${props.sm_padding};
  }`}
  ${props =>
    props.xs_padding &&
    `
  @media ${theme.xs_max} {
    padding: ${props.xs_padding};
  }`}
`;
export default ({ children, ...rest }: Props) => {
  return <Container {...rest}>{children}</Container>;
};
