import { useState } from 'react';
import styled from 'styled-components';
import Button from '/components/ui/Button';
import Textbox from '/components/ui/Textbox';
import InputGroup from '/components/ui/InputGroup';
import ButtonBack from '/components/ui/ButtonBack';
import theme from '/style/theme';
import axios from 'axios';
import getConfig from 'next/config';
import { connect } from 'react-redux';
import actions from '/store/actions';
import checkEmailRule from '/utils/checkEmailRule';
import useTranslation from 'next-translate/useTranslation';

const { publicRuntimeConfig } = getConfig();

const Wrapper = styled.div`
  margin-top: -75px;
  @media ${theme.sm} {
    margin-top: 0;
  }
`;
const Description = styled.p`
  display: none;
  margin-bottom: 30px;
  line-height: 1.64;
  text-align: left;
  color: ${props => props.theme.mid_gy};
  @media ${theme.sm} {
    display: block;
  }
`;

const FindPassword = ({
  // from parent
  description,
  // from store
  changeSubmitting,
  changeSweetAlert,
  changeModalMember,
  uistatus,
  subdomain,
}) => {
  const [email, setValue] = useState('');
  const { t } = useTranslation('common');

  const handleClickBack = () => {
    changeModalMember({
      type: 'login',
      show: true,
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (!email) {
      changeSweetAlert({
        show: true,
        title: t('popup.validation.empty-email'),
      });
      return;
    }
    if (!checkEmailRule(email)) {
      changeSweetAlert({
        show: true,
        title: t('popup.validation.invalid-email'),
      });
      return;
    }
    changeSubmitting(true);
    // password change actions
    const form = new FormData();
    form.append('email', email);
    let endpoint = `${publicRuntimeConfig.API_PATH}/user/password/reset`;
    if (subdomain === 'business') {
      endpoint = `${publicRuntimeConfig.API_PATH}/business/user/password/reset`;
    }
    axios
      .post(endpoint, form)
      .then(() => {
        changeSubmitting(false);
        changeSweetAlert({
          show: true,
          title: t('popup.message.reset-password-success'),
          type: 'success',
          onConfirm: changeModalMember({ show: false }),
        });
      })
      .catch(err => {
        console.log(err);
        changeSubmitting(false);
        if (err.response) {
          changeSweetAlert({
            show: true,
            title: t('popup.validation.unregistered-email'),
          });
        } else {
          changeSweetAlert({
            show: true,
            title: t('popup.message.reset-password-failed'),
          });
        }
      });
  };
  return (
    <Wrapper>
      <ButtonBack onClick={handleClickBack} white />
      <Description dangerouslySetInnerHTML={{ __html: description }} />
      <form className="form-password" onSubmit={handleSubmit} noValidate>
        <InputGroup no_margin>
          <Textbox
            id="email"
            name="email"
            placeholder={t('popup.form.registered-email')}
            value={email}
            onChange={e => {
              setValue(e.target.value);
            }}
            autoComplete="email"
          />
        </InputGroup>
        <InputGroup>
          <Button full submit gradient disabled={uistatus.submitting}>
            {t('popup.reset-my-password')}
          </Button>
        </InputGroup>
      </form>
    </Wrapper>
  );
};

export default connect(
  state => ({
    subdomain: state.subdomain,
    currency: state.currency,
    uistatus: state.uistatus,
  }),
  {
    changeSubmitting: actions.changeSubmitting,
    changeSweetAlert: actions.changeSweetAlert,
    changeModalMember: actions.changeModalMember,
  },
)(FindPassword);
