import React from 'react';
import styled from 'styled-components';
import { transition } from '/style/mixin';
import theme from '/style/theme';

interface Props {
  white?: boolean;
  mint?: boolean;
  mint_alt?: boolean;
  small?: boolean;
  margin?: string;
}
const Wrapper = styled.label<Props>`
  position: relative;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }
  ${props =>
    props.margin &&
    `
    margin: ${props.margin};
    &:first-child {
      margin: ${props.margin};
    }
  `}
  input {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    clip: rect(0,0,0,0);
  }
  .label{
    display: inline-block;
    height: 50px;
    padding: 15px 12px;
    vertical-align: top;
    font-size: 16px;
    font-weight: ${theme.normal};
    color: ${theme.mid_gy};
    line-height: 1.2;
    background-color: ${theme.light_gy2};
    border: solid 1px ${theme.line_gy};
    border-radius: 5px;
    ${transition()}
    ${props =>
      props.small &&
      `
      height: 36px;
      padding: 10px;
      line-height: 16px;
      font-size: 14px;
    `}
    ${props =>
      props.white &&
      `
      background-color: #fff;
      &:hover {
        background-color: ${theme.light_gy2};
        color: ${theme.gy};
      }
    `}
  }
  input:checked + .label {
    color: ${theme.brand};
    background-color: ${theme.light_og2};
    border-color: ${theme.brand};
    ${props =>
      props.mint &&
      `
      color: #fff;
      border-color: ${theme.trazy_mint};
      background-color: ${theme.light_mint3};
    `}
    ${props =>
      props.mint_alt &&
      `
      background-color: #a7dfdf;
      border-color: #6cc9c9;
      color: #15827f;
    `}
  }
`;

const CheckButton: React.FC<Props & React.HTMLProps<HTMLInputElement>> = ({
  white,
  mint,
  mint_alt,
  small,
  children,
  margin,
  ...rest
}) => {
  return (
    <Wrapper
      white={white}
      small={small}
      mint={mint}
      mint_alt={mint_alt}
      margin={margin}
    >
      <input type="checkbox" {...rest} />
      <span className="label">{children}</span>
    </Wrapper>
  );
};

export default CheckButton;
