import Link from 'next/link';
import styled from 'styled-components';
import FooterTitle from './FooterTitle';
import FooterText from './FooterText';
import { clearfix } from '/style/mixin';
import theme from '/style/theme';
import useTranslation from 'next-translate/useTranslation';

const Wrapper = styled.div``;
const Channels = styled.div`
  ${clearfix}
  margin-top: 15px;
  a {
    float: left;
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
    @media ${theme.sm} {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

const CustomerCenter = () => {
  const { t, lang } = useTranslation('common');
  return (
    <Wrapper>
      <FooterTitle>{t('cs-title')}</FooterTitle>
      <FooterText>{t('cs-operation-hours')}
      {lang != 'en' && t('cs-english-only') !== 'common:cs-english-only' ? <><br />{t('cs-english-only')}</> : null}
      </FooterText>
      <Channels>
        {/*
        <a
          href="https://pf.kakao.com/_BIxlKl"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src={require('/public/images/footer/footer-kakao.png')}
            srcSet={`
                ${require('/public/images/footer/footer-kakao.png')} 1x,
                ${require('/public/images/footer/footer-kakao@2x.png')} 2x,
                ${require('/public/images/footer/footer-kakao@3x.png')} 3x
                `}
            alt="kakaotalk"
            title="KakaoTalk"
          />
        </a>
        <a
          href="http://line.me/ti/p/@cea1141w"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src={require('/public/images/footer/footer-line.png')}
            srcSet={`
                ${require('/public/images/footer/footer-line.png')} 1x,
                ${require('/public/images/footer/footer-line@2x.png')} 2x,
                ${require('/public/images/footer/footer-line@3x.png')} 3x
                `}
            alt="line"
            title="Line"
          />
        </a>
        */}
        <Link href="/experience/custom_trip">
          <a>
            <img
              src={require('/public/images/footer/footer-question.png')}
              srcSet={`
                  ${require('/public/images/footer/footer-question.png')} 1x,
                  ${require('/public/images/footer/footer-question@2x.png')} 2x,
                  ${require('/public/images/footer/footer-question@3x.png')} 3x
                  `}
              alt="question"
              title="Custom Trip"
            />
          </a>
        </Link>
        <Link href="/main/footer/talktous">
          <a>
            <img
              src={require('/public/images/footer/footer-email.png')}
              srcSet={`
                  ${require('/public/images/footer/footer-email.png')} 1x,
                  ${require('/public/images/footer/footer-email@2x.png')} 2x,
                  ${require('/public/images/footer/footer-email@3x.png')} 3x
                  `}
              alt="email"
              title="Talk to Us"
            />
          </a>
        </Link>
      </Channels>
    </Wrapper>
  );
};

export default CustomerCenter;
