import React from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import Close from '/components/ui/Close';
import ButtonBack from '/components/ui/ButtonBack';

interface Props {
  back: boolean;
  brand: boolean;
  title: string;
  closeLayer: () => void;
}
const Wrapper = styled.div`
  position: fixed;
  z-index: 201;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
`;
const LayerHeader = styled.div<{ brand: boolean }>`
  font-size: 15px;
  font-weight: ${theme.bold};
  color: ${theme.dark_gy};
  text-align: center;
  line-height: 50px;
  height: 50px;
  border-bottom: 1px solid ${theme.line_gy};
  width: 100%;
  ${props =>
    props.brand &&
    `
    background-color: #f46c4b;
    color: #fff;
  `};
`;
const LayerContent = styled.div`
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
`;

const Layer: React.FC<Props> = ({
  back,
  brand,
  closeLayer,
  title,
  children,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <LayerHeader brand={brand}>
        {back ? (
          <ButtonBack
            white
            onClick={closeLayer}
            style={{ top: '6px', left: '5px' }}
          >
            <></>
          </ButtonBack>
        ) : null}
        {title}
        <Close
          backgroundColor={brand ? '#fff' : theme.mid_gy}
          style={{ top: '5px', right: '10px' }}
          onClick={closeLayer}
        />
      </LayerHeader>
      <LayerContent>{children}</LayerContent>
    </Wrapper>
  );
};

export default Layer;
