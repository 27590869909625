import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import { transition } from '/style/mixin';
import { connect } from 'react-redux';

const Wrapper = styled.span`
  position: relative;
  display: block;
  .placeholder-fix {
    display: block;
    position: absolute;
    top: 18px;
    padding-left: 16px;
    padding-right: 1px;
    font-size: 14px;
    line-height: 16px;
    font-weight: ${theme.bold};
    color: ${theme.brand};
    ${props =>
      props.mint &&
      `
                color: ${theme.trazy_mint};
        `}
    @media ${theme.xs_max} {
      font-size: 16px;
    }
  }
`;
const Input = styled.input`
  &[type="text"],
  &[type="password"],
  &[type="date"],
  &[type="number"],
  &[type="time"] {
    /* for override basic style*/
    outline: none;
    font-size: 14px;
    box-shadow: none;
    border-radius: 0;
    -webkit-appearance: none;
    width: 100%;
    height: 50px;
    line-height: 20px;
    padding: 15px 16px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid ${theme.line_gy};
    color: ${theme.mid_gy};
    ${transition(
      'padding 0s, color .15s ease-out, background-color .15s ease-out, box-shadow .15s ease-out, border .15s ease-out'
    )}
    @media ${theme.sm_max} {
      font-size: 16px;
    }
    &:focus {
      border: solid 1px ${theme.brand};
      color: #f05634;
      ${props =>
        props.mint &&
        `
        color: ${theme.trazy_mint};
        border: solid 1px ${theme.trazy_mint};
      `}
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${theme.gy};
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${theme.gy};
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${theme.gy};
    }
    &:disabled,
    &:read-only {
      background-color: ${theme.light_gy1};
    }
    ${props =>
      props.noRadius &&
      `
        border-radius: 0!important;
    `}
    ${props =>
      props.error &&
      `
        border-color: ${theme.trazy_og};
        background-image: url(${require('/public/images/ui/ico-exclamation.svg')});
        background-repeat: no-repeat;
        background-position: 98.5% center;
    `}
    ${props =>
      props.alignCenter &&
      `
        text-align: center;
    `}
  }
`;
const IconEye = styled.span`
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 6px;
  width: 38px;
  height: 38px;
  background-image: url(${require('/public/images/ui/ico-password_hide.svg')});
  ${props =>
    props.input_type === 'text' &&
    `
    background-image: url(${require('/public/images/ui/ico-password_show.svg')});
  `}
`;
const changeInputWidth = ({
  ref_placeholder,
  add_padding_left,
  setPaddingLeft,
}) => {
  if (ref_placeholder.current) {
    let ref_width = ref_placeholder.current.clientWidth;
    if (add_padding_left) {
      ref_width += add_padding_left;
    }
    setPaddingLeft(ref_width);
  }
};

const Textbox = ({
  id,
  noRadius,
  mint,
  fixed_placeholder,
  error,
  can_switch_type,
  type,
  uistatus,
  add_padding_left,
  alignCenter,
  forwardedRef,
  ...rest
}) => {
  const [padding_left, setPaddingLeft] = useState(null);
  const [input_type, setInputType] = useState(type || 'text');
  let ref_placeholder = useRef();
  useEffect(() => {
    changeInputWidth({ ref_placeholder, add_padding_left, setPaddingLeft });
    if (can_switch_type) {
      setInputType('password');
    }
  }, [uistatus.screenwidth]);
  return (
    <Wrapper>
      <Input
        type={input_type}
        noRadius={noRadius}
        mint={mint}
        id={id}
        style={{ paddingLeft: padding_left }}
        error={error}
        alignCenter={alignCenter}
        ref={forwardedRef}
        {...rest}
      />
      {can_switch_type ? (
        <IconEye
          input_type={input_type}
          onClick={() =>
            setInputType(input_type === 'password' ? 'text' : 'password')
          }
        />
      ) : null}
      {fixed_placeholder ? (
        <label className="placeholder-fix" htmlFor={id} ref={ref_placeholder}>
          {fixed_placeholder}
        </label>
      ) : null}
    </Wrapper>
  );
};

export default connect(state => ({ uistatus: state.uistatus }))(Textbox);
