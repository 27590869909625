import { Map } from 'immutable';
import getCustomPrice from './getCustomPrice';

const deleteEmptyObj = (output, idx_arr) => {
  // delete participant object when empty
  const idx_arr_with_count = [...idx_arr, 'qty'];
  // get option info
  const option_index = idx_arr_with_count.slice(0, -2);
  const option_item = output.getIn(option_index);
  const total_quantity = option_item.valueSeq().reduce((acc, val) => {
    return acc + val.get('qty');
  }, 0);
  if (!total_quantity) {
    const date_index = idx_arr_with_count[0];
    output = output.delete(date_index);
    // console.log(optionOnTotalDummy.toJS(), date_index);
  }
  return output;
};
const getCaculatedQuantity = ({
  target_name,
  target_map,
  target,
  type,
  idx_arr = [],
}) => {
  let output = Map({});
  const current = target.get('qty') ? target.get('qty') : 0;
  const increment =
    type === '+' ? target.get('increment') : -1 * target.get('increment');
  let number_output = current + increment;
  if (type === '+') {
    if (current == 0) {
      number_output = target.get('min_participant');
    }
  }
  if (number_output < target.get('min_participant')) {
    number_output = 0;
    if (target.get('is_mandatory')) {
      number_output = target.get('min_participant');
    }
  } else if (number_output > target.get('max_participant')) {
    number_output = target.get('max_participant');
  }
  const limit = target_map.getIn([idx_arr[0], 'participant_limit']);
  if (target_name === 'optionOnCart' && limit) {
    // if it's in cart with limit it cannot be changed
    return target_map;
  }
  if (
    number_output > target.get('max_participant') || // must be under max participant
    number_output < 0 || //  cannont be under 0
    (target.get('is_mandatory') &&
      number_output < target.get('min_participant'))
  ) {
    // if has is_mandatory, min participant is minimum value
    return target_map;
  }
  // add price change logic
  output = target_map
    .setIn([...idx_arr, 'qty'], number_output)
    .setIn([...idx_arr, 'participant_type'], target.get('participant_type'))
    .setIn([...idx_arr, 'participant_type_en'], target.get('participant_type_en'));
  if (target_map.getIn([idx_arr[0], 'user_cart_idx'])) {
    output = output.setIn(
      [...idx_arr, 'user_cart_idx'],
      target_map.getIn([idx_arr[0], 'user_cart_idx'])
    );
  }
  if (target.get('is_customized')) {
    // if has is_customized, add price change
    let filtered = {
      price: target.get('price'),
      original_price: target.get('original_price'),
      fixed_price_setting: target.get('fixed_price_setting'),
      price_net: target.get('price_net'),
    };
    if (target.get('custom_prices').length) {
      filtered = getCustomPrice(number_output, target.get('custom_prices'));
    }
    output = output
      .setIn([...idx_arr, 'price'], filtered.price)
      .setIn([...idx_arr, 'original_price'], filtered.original_price)
      .setIn([...idx_arr, 'fixed_price_setting'], filtered.fixed_price_setting)
      .setIn([...idx_arr, 'price_net'], filtered.price_net);
  }
  if (target_name === 'option_total' && number_output === 0) {
    // zero check loop option on total
    // delete participant object when empty
    output = deleteEmptyObj(output, idx_arr);
  }
  return output;
};

export default getCaculatedQuantity;
