import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 20px;
  text-align: center;
  ${props => props.right &&`
    text-align: right;
  `}
`;

const ButtonGroup = ({children, ...rest}) => {
  return (
    <Wrapper {...rest}>
      {children}
    </Wrapper>
  );
};

export default ButtonGroup;
