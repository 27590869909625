import styled, { keyframes } from 'styled-components';
import theme from '../../style/theme';
import Scroll from 'react-scroll';

interface Props {
  show: boolean;
  mobile_hide_top_button: boolean;
}
const scroll = Scroll.animateScroll;
const Wrapper = styled.button<Props>`
  cursor: pointer;
  position: fixed;
  z-index: 100;
  bottom: 50px;
  right: 50px;
  padding: 0;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  opacity: ${props => (props.show ? 1 : 0)};
  transition: all ease-out 0.2s;
  @media ${theme.md_max} {
    bottom: 20px;
    right: 20px;
  }
  @media ${theme.sm_max} {
    right: 10px;
  }
  ${props =>
    props.mobile_hide_top_button &&
    `
    @media ${theme.xs_max} {
      display: none;
    }
  `}
  span {
    float: left;
    vertical-align: top;
    svg {
      width: 52px;
      height: 52px;
    }
  }
`;
const ButtonTop = ({ show, mobile_hide_top_button, ...rest }: Props) => {
  return (
    <Wrapper
      {...rest}
      onClick={() => scroll.scrollToTop({ duration: 500 })}
      show={show}
      mobile_hide_top_button={mobile_hide_top_button}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: require('../../public/images/ui/ico-btn-top.svg?include'),
        }}
      />
    </Wrapper>
  );
};

export default ButtonTop;
