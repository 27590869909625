//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { resetList } from '/style/mixin';
import { connect } from 'react-redux';
import actions from '/store/actions';
import theme from '/style/theme';

const Wrapper = styled.div`
  @media ${theme.md} {
    position: absolute;
    z-index: 250;
    top: 60px;
    right: -10px;
    width: 438px;
    padding: 20px 10px;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
  }
  .menu {
    ${resetList}
    > li {
      @media ${theme.md} {
        float: left;
        width: 50%;
        padding: 0 10px;
        &:last-child {
          margin-top: 10px;
        }
      }
    }
    .submenu {
      padding: 5px 0 15px 20px;
      @media ${theme.md} {
        padding: 0;
        margin-top: 10px;
      }
      &-item {
        font-size: 14px;
        line-height: 38px;
        letter-spacing: -0.2px;
        color: ${theme.gy};
        white-space: nowrap;
        span {
          display: block;
          cursor: pointer;
          &.active {
            color: ${theme.brand};
            strong {
              color: ${theme.brand};
            }
          }
        }
        strong {
          display: inline-block;
          padding-left: 10px;
          margin-right: 10px;
          font-size: 15px;
          line-height: 38px;
          font-weight: ${theme.normal};
          color: ${theme.mid_gy};
        }
      }
    }
  }
  .region {
    display: block;
    padding: 10px;
    line-height: 1.2;
    font-size: 14px;
    font-weight: ${theme.bold};
    letter-spacing: -0.2px;
    color: ${theme.mid_gy};
    background-color: ${theme.light_gy1};
  }
`;
interface Props {}

const group_name = ['Asia-Pacific', 'The Americas', 'Europe'];
const CurrencyMenu: React.FC<Props> = ({
  toggleOpen,
  currency,
  changeCurrency,
}) => {
  let group = [];
  if (currency.data) {
    group = currency.data;
  }
  return (
    <Wrapper>
      <ul className="menu">
        {group_name.map((v, i) => {
          return (
            <li className="menu-item" key={`group${i}`}>
              <span className="region">{v}</span>
              {group.length ? (
                <ul className="submenu">
                  {group
                    .filter(w => w.currency_group === i + 1)
                    .sort((x, y) => {
                      if (x.currency_name < y.currency_name) return -1;
                      else if (x.currency_name > y.currency_name) return 1;
                      return 0;
                    })
                    .map((z, i) => {
                      return (
                        <li className="submenu-item" key={`currency${i}`}>
                          <span
                            className={
                              z.currency_name == currency.tr_currency
                                ? 'active'
                                : ''
                            }
                            onClick={() => {
                              changeCurrency({
                                tr_currency: z.currency_name,
                                tr_currency_source: 'manual',
                              });
                              toggleOpen(false);
                            }}
                          >
                            <strong className="notranslate">
                              {z.currency_name}
                            </strong>
                            {z.currency_long_name}
                          </span>
                        </li>
                      );
                    })}
                </ul>
              ) : null}
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default connect(state => state, actions)(CurrencyMenu);
