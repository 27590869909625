import Link from 'next/link';
import { connect } from 'react-redux';
import actions from '/store/actions';
import useTranslation from 'next-translate/useTranslation';
import Carousel from "../ui/Carousel";
import styled from "styled-components";
import React, {useState} from "react";
import CityItemMini from "../list/CityItemMini";
import OtherCountriesButton from "./OtherCountriesButton";

const CarouselWrap = styled.div`
  margin: 0 -5px;
`;

const CityMenu = ({ thumbnail, country_id, hide_other_countries, cities }) => {
  const { t } = useTranslation('common');
  const [open, toggleOpen] = useState(false);
  let country_menu_list = cities && cities.length ? cities.filter((country) => country.show_menu) : [];
  /*if (country_id) {
    country_menu_list = country_menu_list.filter((country) => country.country_id == country_id);
  }*/
  return (
    <>
      {country_menu_list && country_menu_list.length ? (
        <ul className="menu">
          {country_menu_list.map((country, i) => {
            if (thumbnail && country.is_thumbnail_menu) {
              return (<li className="menu-item thumbnail-menu-item" key={`country${i}`}>
                <div className="link-wrapper">
                  {country.has_page && country.custom_url ? (
                      <Link
                          as={`/things-to-do/${country.custom_url}`}
                          href={`/things-to-do/[slug]`}
                      >
                        <a title={`Top Things To Do in ${country.country_name_en}`}>{country.country_name}</a>
                      </Link>
                  ) : (
                      <span>{country.country_name}</span>
                  )}
                </div>
                {country.city_list ? (
                  <div className="thumbnail-list">
                    <CarouselWrap>
                      <Carousel variableWidth disable_desktop dynamicItemPerPage>
                        {country.city_list.map((city, i) => {
                          return <CityItemMini data={city} key={`city${i}`} small />;
                        })}
                      </Carousel>
                    </CarouselWrap>
                  </div>
                ) : null}
                {/*<div className="link-wrapper">
                  <Link href={`/campaign/korea-proxy-shopping`}>
                    <a title="Korea Proxy Shopping">{t('header.proxy-shopping')}</a>
                  </Link>
                </div>*/}
                {hide_other_countries ?
                  <div className="other-countries-btn-wrapper">
                    <OtherCountriesButton onClick={() => toggleOpen(!open)}></OtherCountriesButton>
                    <div className="clearfix"></div>
                  </div>
                : null }
              </li>)
            } else {
              return (
                  <li className="menu-item" key={`country${i}`} hidden={!country.is_thumbnail_menu && hide_other_countries && !open}>
                    <div className="link-wrapper">
                      {country.has_page && country.custom_url ? (
                          <Link
                              as={`/things-to-do/${country.custom_url}`}
                              href={`/things-to-do/[slug]`}
                          >
                            <a title={`Top Things To Do in ${country.country_name_en}`}>{country.country_name}</a>
                          </Link>
                      ) : (
                          <span>{country.country_name}</span>
                      )}
                    </div>
                    {country.city_list ? (
                        <ul className="submenu">
                          {country.city_list.map((city, i) => {
                            return (
                                <li className="submenu-item" key={`city${i}`}>
                                  {city.has_page && city.custom_url ? (
                                      <Link
                                          as={`/things-to-do/${city.custom_url}`}
                                          href={`/things-to-do/[slug]`}
                                      >
                                        <a title={`Top Things To Do in ${city.city_name_en}`}>{city.city_name}</a>
                                      </Link>
                                  ) : (
                                      <span>{city.city_name}</span>
                                  )}
                                </li>
                            );
                          })}
                          {/*
                            country.country_id == 133 ? (
                                <li className="submenu-item proxy-link" key={`proxy-link`}>
                                  <Link href={`/campaign/korea-proxy-shopping`}>
                                    <a title="Korea Proxy Shopping">{t('header.proxy-shopping')}</a>
                                  </Link>
                                </li>
                            ) : null
                          */}
                        </ul>
                    ) : null}
                    {country.country_id.is_thumbnail_menu && hide_other_countries ?
                      <div className="other-countries-btn-wrapper">
                        <OtherCountriesButton onClick={() => toggleOpen(!open)}></OtherCountriesButton>
                        <div className="clearfix"></div>
                      </div>
                    : null }
                  </li>
              );
            }
          })}
        </ul>
      ) : null}
    </>
  );
};

export default connect(state => state, actions)(CityMenu);
