import { useState } from 'react';
import styled from 'styled-components';
import ChevronButton from '/components/ui/ChevronButton';
import BgLayer from '/components/ui/BgLayer';
import { connect } from 'react-redux';
import actions from '/store/actions';
import theme from '/style/theme';
import CurrencyMenu from './CurrencyMenu';

const Currency = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: top;
`;

const CurrencyChanger = ({ currency }) => {
  const [open, toggleOpen] = useState(false);
  return (
    <Currency>
      <ChevronButton
        className={`notranslate`}
        active={open}
        onClick={() => toggleOpen(!open)}
        active_color={theme.brand}
        style={{ paddingRight: 0 }}
      >
        {currency.tr_currency}
      </ChevronButton>
      {open ? (
        <>
          <BgLayer onClick={() => toggleOpen(false)} transparent />
          <CurrencyMenu toggleOpen={toggleOpen} />
        </>
      ) : null}
    </Currency>
  );
};

export default connect(state => state, actions)(CurrencyChanger);
