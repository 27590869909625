import { connect } from 'react-redux';
import actions from '/store/actions';
import convertPrice from '/utils/convertPrice';
import { PriceType } from '../../type/TypePrice';

const Price = ({
  price,
  from_currency,
  to_currency,
  use_symbol,
  use_format_money,
  force_show,
  fixed_price_setting,
  ceil_increment,
  currency,
}: PriceType) => {
  let output: string = '';
  if (!(currency && currency.data && currency.data.length)) return <></>;
  if (!from_currency) {
    from_currency = currency.tr_currency || 'USD';
  }
  if (!to_currency) {
    to_currency = currency.tr_currency || 'USD';
  }
  output = convertPrice({
    price,
    from_currency,
    to_currency,
    use_symbol,
    use_format_money,
    force_show,
    fixed_price_setting,
    ceil_increment,
    currency_info: currency.data,
  });
  return <>{output}</>;
};

Price.defaultProps = {
  price: 0,
  from_currency: '',
  to_currency: '',
  use_symbol: true,
  use_format_money: true,
  force_show: false,
  fixed_price_setting: {},
  ceil_increment: false,
} as Partial<PriceType>;

export default connect(state => state, actions)(Price);
