import React, { Component } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import actions from '/store/actions';
import { connect } from 'react-redux';
import Select from "/components/ui/Select";

const Wrapper = styled.section`
  .checkinout-label{
    margin-right: 10px;
    &.checkout-label {
      margin-left: 30px;
    }
  }
`;

class StarRatingSelect extends Component {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }
  handleSelectChange(name, e) {
    this.updateState(name, e.value);
  }
  updateState(name,values) {
    this.props.onUpdateState(name,values);
  }
  render() {
    let hour_options = [];
    for(let h=0;h<=23;h++) {
      const value = h.toString().padStart(2,'0');
      hour_options.push({value:value, label: value});
    }
    let minute_options = [];
    for(let m=0;m<=55;m+=5) {
      const value = m.toString().padStart(2,'0');
      minute_options.push({value:value, label: value});
    }
    const {checkin_hour, checkin_minute, checkout_hour, checkout_minute, inside_modal} = this.props;
    return (
      <Wrapper>
        <span className="checkinout-label">Check in</span>
        <Select
          inside_modal={inside_modal}
          isSearchable={false}
          options={hour_options}
          defaultValue={hour_options[0]}
          value={{value:checkin_hour,label:checkin_hour}}
          time_selector={true}
          onChange={e => {
            this.handleSelectChange("checkin_hour", e);
            if (!checkin_minute) {
                this.updateState("checkin_minute", "00");
            }
          }}
        ></Select>
        <span className="time-separator"> : </span>
        <Select
          inside_modal={inside_modal}
          isSearchable={false}
          options={minute_options}
          defaultValue={minute_options[0]}
          value={{value:checkin_minute,label:checkin_minute}}
          time_selector={true}
          onChange={e => {
            this.handleSelectChange("checkin_minute", e);
            if (!checkin_hour) {
              this.updateState("checkin_hour", "00");
            }
          }}
        ></Select>
        <span className="checkinout-label checkout-label">Check out</span>
        <Select
          inside_modal={inside_modal}
          isSearchable={false}
          options={hour_options}
          defaultValue={hour_options[0]}
          value={{value:checkout_hour,label:checkout_hour}}
          time_selector={true}
          onChange={e => {
              this.handleSelectChange("checkout_hour", e);
              if (!checkout_minute) {
                  this.updateState("checkout_minute", "00");
              }
          }}
        ></Select>
        <span className="time-separator"> : </span>
        <Select
          inside_modal={inside_modal}
          isSearchable={false}
          options={minute_options}
          defaultValue={minute_options[0]}
          value={{value:checkout_minute,label:checkout_minute}}
          time_selector={true}
          onChange={e => {
            this.handleSelectChange("checkout_minute", e);
            if (!checkout_hour) {
              this.updateState("checkout_hour", "00");
            }
          }}
        ></Select>
      </Wrapper>
    );
  }
}

export default connect(state => state, actions)(StarRatingSelect);
