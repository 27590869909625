import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import theme from '/style/theme';
import { backgroundCover } from 'style/mixin';
import Carousel from './Carousel';
import { connect } from 'react-redux';
import actions from '/store/actions';
import useTranslation from 'next-translate/useTranslation';

const Wrapper = styled.div``;
const DropArea = styled.div`
  position: relative;
  margin: 0;
  padding: 19px;
  height: 80px;
  border-radius: 5px;
  border: solid 1px ${theme.light_og};
  background-color: ${theme.light_og1};
  text-align: center;
  color: ${theme.light_og};
  font-weight: ${theme.bold};
  outline: 0;
  cursor: pointer;
  input {
    outline: 0;
  }
  span {
    vertical-align: middle;
  }
`;
const ThumbnailWrapper = styled.div`
  padding: 0 3px;
`;
const Thumbnail = styled.div`
  background-color: #c6c6c6;
  position: relative;
  width: 80px;
  height: 80px;
  background-image: url("${props => props.background_image}");
  ${backgroundCover}
`;
const Close = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  text-indent: -9999px;
  display: block;
  width: 38px;
  height: 38px;
  background-position: -76px 0;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/img_write_review.png');
`;
const CarouselWrapper = styled.div`
  position: relative;
  margin-top: 10px;
  padding: 0;
  .slick-arrow {
    bottom: 20px;
    width: 38px;
    height: 38px;
    &.slick-next {
      background-position: -114px 0;
      right: -30px;
      background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/arrow-img-multi-01.png');
    }
    &.slick-prev {
      background-position: -76px 0;
      left: -30px;
      background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/arrow-img-multi-01.png');
    }
  }
`;
const Dropzone = ({ files, setFiles, changeSweetAlert, removeFileByIndex }) => {
  const { t } = useTranslation('common');
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpeg',
    maxSize: 5242880,
    onDrop: acceptedFiles => {
      const new_files = acceptedFiles
        .filter(v => {
          return !files.some(file => file.name === v.name);
        })
        .map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      const concat_files = [...files, ...new_files];
      if (concat_files.length > 20) {
        changeSweetAlert(
          'Failed to upload files! You can upload up to 20 files'
        );
        return;
      }
      setFiles(concat_files);
    },
    onDropRejected: res => {
      if (res && res.length) {
        const over_max_size = res.some(v => v.size > 5242880);
        if (over_max_size)
          changeSweetAlert('Oops! Please add a pic less than 5MB.');
      }
    },
  });
  return (
    <Wrapper>
      <DropArea {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <span
          dangerouslySetInnerHTML={{
            __html: require('../../public/images/ui/ico-review-photo.svg?include'),
          }}
        />
        {t('click-drag-photo')}
      </DropArea>
      {files.length ? (
        <CarouselWrapper>
          <Carousel variableWidth noDots>
            {files.map((file, i) => {
              return (
                <div key={`thumbnail${i}`}>
                  <ThumbnailWrapper>
                    <Thumbnail background_image={file.preview}>
                      <Close onClick={() => removeFileByIndex(i)}>close</Close>
                    </Thumbnail>
                  </ThumbnailWrapper>
                </div>
              );
            })}
          </Carousel>
        </CarouselWrapper>
      ) : null}
    </Wrapper>
  );
};

export default connect(null, {
  changeSweetAlert: actions.changeSweetAlert,
})(Dropzone);
