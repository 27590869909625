import React, { Component } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import actions from '/store/actions';
import { connect } from 'react-redux';
import Radio from "/components/ui/Radio";
import Textbox from "/components/ui/Textbox";
import withTranslation from "next-translate/withTranslation";

const Wrapper = styled.div`
  .agelimit-input-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 50px;
    margin-left: 10px;
    input[type="text"] {
      &:disabled{
        border-color: ${theme.line_gy};
        background-color: ${theme.light_gy1};
        color:${theme.gy};
      }
    }
  }
  .option-item{
    &.inline {
      display: inline-block;
      vertical-align:top;
      padding-top: 17px;
      height: 50px;
      label {
        line-height: 17px;
      }
    }
  }
  .agelimit-label{
    display: inline-block;
    line-height: 50px;
    margin-left: 10px;
    margin-right: 34px;
  }
`;

class AgeLimitInput extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(e) {
    this.props.onHandleInputChange(e);
  }
  render() {
    const {has_age_limit, age_limit} = this.props;
    const { t } = this.props.i18n;
    return (
      <Wrapper>
        <div className="option-item inline"><Radio value={1} name="has_age_limit" checked={has_age_limit == 1} onChange={this.handleInputChange}>Yes</Radio></div>
        <div className="agelimit-input-wrapper">
          <Textbox type="text" name="age_limit" className="agelimit-input" value={age_limit} onChange={this.handleInputChange} disabled={has_age_limit == 0}></Textbox>
        </div>
        <span className="agelimit-label">{t('age-limit-up')}</span>
        <div className="option-item inline"><Radio value={0} name="has_age_limit" checked={has_age_limit == 0} onChange={this.handleInputChange}>No</Radio></div>
      </Wrapper>
    );
  }
}

export default connect(state => state, actions)(withTranslation(AgeLimitInput, 'spot'));
