import styled from "styled-components";
import { transition, resetList } from "/style/mixin";

const SkipLinks = ({ children, ...rest }) => {
  const Wrapper = styled.ul`
    position: fixed;
    z-index: 300;
    top: -50px;
    left: 0;
    right: 0;
    ${resetList}
    a {
      display: block;
    }
    .inner {
      display: block;
      background-color: ${props => props.theme.brand};
      color: #fff;
      text-align: center;
      height: 50px;
      line-height: 50px;
      transform: translateY(0px);
      ${transition()}
    }
    a:focus > .inner  {
      transform: translateY(50px);
    } 
  `;
  return (
    <Wrapper className="skip-links">
      <li><a href="#site-main"><span className="inner" tabIndex="-1">
      Skip to main content
    </span></a></li>
    </Wrapper>
  )
};

export default SkipLinks;

