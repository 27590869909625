// @ts-nocheck
import React from 'react';
import { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '/components/ui/Button';
import MenuItemMobile from './MenuItemMobile';
import theme from '/style/theme';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import actions from '/store/actions';
import Profile from '/components/ui/Profile';
import CurrencyMenu from '../CurrencyMenu';
import Layer from '/components/ui/Layer';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import LanguageMenu from '../LanguageMenu';

const slideIn = keyframes`
  from {
    transform:translateX(-280px);
  }
  to {
    transform:translateX(0);
  }
`;
const Wrapper = styled.div`
  position: fixed;
  z-index: 300;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${theme.light_gy1};
  width: 280px;
  animation: ${slideIn} 0.3s ease-out;
  .menu-header {
    margin-bottom: 10px;
  }
  .user-header {
    display: flex;
    align-items: center;
    padding: 30px 20px;
    overflow: hidden;
    background-color: ${theme.light_og};
    .text {
      float: left;
      .name {
        font-size: 16px;
        line-height: 19px;
        font-weight: ${theme.bold};
        color: #fff;
      }
      .desc {
        margin-top: 5px;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
      }
    }
  }
  .user-body {
    overflow: hidden;
    background-color: ${theme.trazy_og};
    .points {
      float: left;
      padding: 0 15px;
      color: #fff;
      line-height: 50px;
    }
    .logout {
      float: right;
      width: 80px;
    }
    .logout-business {
      width: 100%;
    }
    .signup {
      float: left;
      width: 50%;
    }
    .login {
      float: left;
      width: 50%;
    }
    .login-business {
      width: 100%;
    }
  }
`;

interface Props {}

const MenuMobile: React.FC<Props> = ({
  // from parent
  close,
  // from store
  subdomain,
  authentication,
  currency,
  language,
  sum_point,
  deauthenticate,
  changeModalMember,
}) => {
  const { locale } = useRouter();
  const cur_language = language?.data?.length ? language.data.find(x => x.locale == locale) : null;
  const [currency_modal, toggleCurrencyModal] = useState(false);
  const [language_modal, toggleLanguageModal] = useState(false);
  const { t } = useTranslation('common');

  const user = authentication.user;
  const is_business_site = subdomain === 'business';

  useEffect(function() {
    document.body.classList.add('side-opened');

    return function cleanup() {
      document.body.classList.remove('side-opened');
    };
  }, []);

  return (
    <Wrapper>
      <div className="menu-header">
        {user ? (
          <>
            <div className="user-header">
              <Profile
                className="profile"
                width={50}
                style={{
                  float: 'left',
                  marginRight: '20px',
                }}
                src={user.profile_pic}
              />
              {is_business_site ? (
                <div className="text">
                  <div className="name">{user.business_name || ''}</div>
                </div>
              ) : (
                <div className="text">
                  <div className="name">
                    {' '}
                    {user.first_name} {user.last_name ? user.last_name.substr(0, 1) : ''}
                  </div>
                  <div className="desc">I am {user.description ? user.description : 'a new Trazer'}</div>
                </div>
              )}
            </div>
            <div className="user-body">
              {!is_business_site ? (
                <div className="points">
                  Total <NumberFormat value={sum_point} displayType={'text'} thousandSeparator={true} prefix={''} />
                  pts
                </div>
              ) : null}
              <Button
                noRadius
                normal
                gray
                onClick={() => {
                  deauthenticate(authentication.token);
                  close();
                }}
                className={is_business_site ? 'logout-business' : 'logout'}
              >
                {t('logout')}
              </Button>
            </div>
          </>
        ) : (
          <div className="user-body">
            <Button
              noRadius
              className={is_business_site ? 'login-business' : 'login'}
              normal
              onClick={() => {
                changeModalMember({
                  show: true,
                  type: 'login',
                });
                close();
              }}
            >
              {t('login')}
            </Button>
            {!is_business_site ? (
              <Button
                noRadius
                className="signup"
                gray
                normal
                onClick={() => {
                  changeModalMember({
                    show: true,
                    type: 'signup',
                  });
                  close();
                }}
              >
                {t('signup')}
              </Button>
            ) : null}
          </div>
        )}
        <MenuItemMobile href="/" home icon>
            {t('side-menu.home')}
        </MenuItemMobile>
        {user ? (
          <MenuItemMobile href="/mybooking?src=side-menu" user icon>
            {t('side-menu.my-account')}
          </MenuItemMobile>
        ) : null}
      </div>
      <div className="menu-content">

        <MenuItemMobile
          onClick={() => {
            toggleCurrencyModal(true);
            return false;
          }}
        >
          {t('side-menu.currency')} <span className="currency">{currency.tr_currency}</span>
        </MenuItemMobile>
        <MenuItemMobile
          onClick={() => {
            toggleLanguageModal(true);
            return false;
          }}
        >
          {t('side-menu.language')} <span className="language">{cur_language ? cur_language.name : null }</span>
        </MenuItemMobile>
        <MenuItemMobile href="/main/footer/faq">{t('faq')}</MenuItemMobile>
        <MenuItemMobile href="/main/footer/talktous">{t('cs-center')}</MenuItemMobile>
        {!is_business_site ? <MenuItemMobile href="/affiliate">{t('affiliate-program')}</MenuItemMobile> : null}
        <MenuItemMobile href="/main/how_to_trazy">{t('how-trazy-works')}</MenuItemMobile>
      </div>
      {currency_modal ? (
        <Layer
          brand
          back
          title="Currency"
          closeLayer={() => {
            toggleCurrencyModal(false);
          }}
        >
          <CurrencyMenu
            toggleOpen={() => {
              toggleCurrencyModal(false);
              close();
            }}
          />
        </Layer>
      ) : null}
      {language_modal ? (
        <Layer
          brand
          back
          title="Language"
          closeLayer={() => {
            toggleLanguageModal(false);
          }}
        >
          <LanguageMenu
            toggleOpen={() => {
              toggleLanguageModal(false);
              close();
            }}
          />
        </Layer>
      ) : null}
    </Wrapper>
  );
};

export default connect(
  state => ({
    uistatus: state.uistatus,
    authentication: state.authentication,
    currency: state.currency,
    language: state.language,
    subdomain: state.subdomain,
    sum_point: state.sum_point,
  }),
  { changeModalMember: actions.changeModalMember, deauthenticate: actions.deauthenticate },
)(MenuMobile);
