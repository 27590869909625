import { Component } from 'react';
import { connect } from 'react-redux';
import actions from '/store/actions';
import styled from 'styled-components';
import { transition } from '/style/mixin';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
import Textbox from '/components/ui/Textbox';
import { debounce } from 'lodash';
import axios from 'axios';
import theme from '/style/theme';
import parse from '/utils/parse';
import Link from 'next/link';
import Chevron from '/components/ui/Chevron';
import Router from 'next/router';
import BgLayer from 'components/ui/BgLayer';
import withTranslation from 'next-translate/withTranslation';

const Search = styled.form`
  position: relative;
  flex: 1 1 auto;
  max-width: 400px;
  height: 50px;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 2.71;
  letter-spacing: -0.2px;
  color: ${theme.dark_gy};
  background-color: ${theme.light_gy1};
  ${transition('background-color .15s ease-out')};
  &:hover {
    background-color: ${theme.light_gy2};
  }
  @media ${theme.sm_max} {
    width: 100%;
    max-width: 100%;
    height: 60px;
    margin: 0;
    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
  }
  .search-text {
    border: 0;
    padding-left: 53px;
    padding-right: 0;
    background-color: transparent;
    box-shadow: none;
    font-size: 14px;
    ::-webkit-input-placeholder,
    ::-ms-input-placeholder {
      color: ${props => props.theme.gy};
    }
    &:focus {
      box-shadow: none;
      border: 0;
      color: ${theme.dark_gy};
    }
    @media ${theme.sm_max} {
      border: 0;
      padding-left: 58px;
      height: 60px;
      font-size: 16px;
    }
  }
  .reset-keyword {
    position: absolute;
    right: 10px;
    top: 50%;
    padding: 0;
    margin-top: -19px;
    cursor: pointer;
  }
  .search-submit {
    position: absolute;
    left: 0;
    top: 0;
    width: 38px;
    height: 38px;
    padding: 0;
    margin: 6px 10px;
    background-color: transparent;
    cursor: pointer;
    svg {
      width: 38px;
      height: 38px;
    }
    @media ${theme.sm_max} {
      margin: 11px 10px;
      svg g {
        stroke: ${theme.brand};
      }
    }
  }
  .autocomplete-items {
    position: absolute;
    z-index: 2;
    border-top: 1px solid ${theme.light_gy2};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5px 5px;
    top: 55px;
    left: 0;
    background-color: #fff;
    width: 400px;
    max-height: 340px;
    overflow-y: auto;
    @media ${theme.sm_max} {
      width: auto;
      box-shadow: none;
      border-radius: 0;
      top: 110px;
      right: 0;
      z-index: 1;
      left: 0;
      bottom: 0;
      position: fixed;
      max-height: none;
      padding: 0;
    }
    .autocomplete-detail {
      position: relative;
      display: block;
      background-color: ${theme.light_gy1};
      font-size: 14px;
      padding: 0 10px;
      text-align: right;
      @media ${theme.sm_max} {
        padding-right: 10px;
      }
    }
    .autocomplete-item {
      cursor: pointer;
      padding: 0 20px 0 25px;
      background-color: #fff;
      ${transition()}
      &:hover {
        background-color: ${theme.light_gy1};
      }
      &:first-child {
        .item-inner {
          border-top: 0;
        }
      }
      &.empty {
        padding: 20px 20px 20px 25px;
        line-height: 18px;
      }
      .item-inner {
        padding: 10px 0;
        width: 100%;
        border-top: 1px solid ${theme.light_gy2};
        .item-content {
          display: table;
          &.marker-offset {
            margin-left: -25px;
          }
          > * {
            display: table-cell;
            vertical-align: top;
            line-height: 23px;
          }
          .status {
            display: inline-block;
            vertical-align: top;
            padding: 0 5px;
            font-size: 12px;
            color: ${theme.mid_gy};
            line-height: 23px;
            border-radius: 5px;
            margin-right: 5px;
            white-space: nowrap;
            background-color: ${theme.line_gy};
          }
          .title {
            font-size: 14px;
            line-height: 23px;
            em {
              color: ${theme.brand};
              font-weight: ${theme.normal};
            }
          }
        }
        .city {
          font-size: 12px;
          line-height: 23px;
          color: ${theme.light_gy4};
        }
      }
      &.location {
        .item-inner .item-content {
          margin-left: -13px;
          .title {
            font-size: 15px;
            vertical-align: middle;
          }
        }
      }
    }
  }
`;
const LocationMarker = styled.div`
  display: inline-block;
  width: 38px;
  height: 38px;
  background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/img_key_info.png');
  background-position: -114px -38px;
  vertical-align: middle;
`;
class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      keyword: this.props.keyword || '',
      activity_items: [],
      location_items: [],
      is_searched: false,
      is_show: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetKeyword = this.resetKeyword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.searchKeyword = debounce(this.searchKeyword.bind(this), 200);
  }
  handleInputChange(event) {
    const target = event.target;
    const keyword = target.value;
    if (keyword === '') {
      this.setState({
        keyword,
        is_searched: false,
      });
    } else {
      this.setState(
        {
          keyword,
        },
        () => this.searchKeyword(keyword)
      );
    }
  }
  resetKeyword() {
    this.setState({
      keyword: '',
      activity_items: [],
      location_items: [],
      is_searched: false,
    });
  }
  searchKeyword(keyword) {
    const { uistatus } = this.props;
    const { lang } = this.props.i18n;
    axios
      .get(`${publicRuntimeConfig.API_PATH}/essearch/auto`, {
        params: {
          keyword,
          item_per_page: uistatus.screenwidth === 'xs' ? 10 : 15,
          locale: lang,
        },
      })
      .then(res => {
        const data = res.data;
        let activity_items = [];
        let location_items = [];
        if (data) {
          if (data.location_items && data.location_items.length) {
            location_items = data.location_items;
          }
          if (data.activity_items && data.activity_items.length) {
            activity_items = data.activity_items;
          }
          this.setState({
            activity_items,
            location_items,
            is_searched: true,
            is_show: true,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  onSubmit(e) {
    e.preventDefault();
    const { keyword } = this.state;
    const { lang } = this.props.i18n;
    let lang_path = '';
    if (lang && lang !== 'en') {
      lang_path = '/' + lang;
    }
    let destination = lang_path + '/search/activities';
    if (Router.asPath.indexOf('/search/places') > -1)
      destination = lang_path + '/search/places';
    if (Router.asPath.indexOf('/search/travel-guides') > -1)
      destination = lang_path + '/search/travel-guides';
    if (keyword) destination = { pathname: destination, query: { keyword } };
    Router.push(lang_path + '/search/[slug]', destination);
  }
  render() {
    const {
      keyword,
      activity_items,
      location_items,
      is_searched,
      is_show,
    } = this.state;
    const { t } = this.props.i18n;
    return (
      <Search onSubmit={this.onSubmit}>
        <fieldset>
          <legend className="screen-reader-text">Search All Items</legend>
          <label htmlFor="global-search-text">
            <span className="sr-only">Global Search</span>
            <Textbox
              id="search-text"
              name="search-text"
              className="search-text"
              placeholder={t('header.search-box-placeholder')}
              value={keyword}
              autoComplete="off"
              onChange={this.handleInputChange}
              onFocus={() => this.setState({ is_show: true })}
            />
          </label>
          <button type="submit" className="search-submit">
            <span
              dangerouslySetInnerHTML={{
                __html: require('../../public/images/header/search.svg?include'),
              }}
            />
          </button>
          {keyword ? (
            <button className="reset-keyword" onClick={this.resetKeyword}>
              <span
                dangerouslySetInnerHTML={{
                  __html: require('../../public/images/ui/ico-clear.svg?include'),
                }}
              />
            </button>
          ) : null}
          {keyword && is_searched ? (
            is_show ? (
              <>
                <BgLayer
                  transparent
                  z_index="1"
                  style={{ top: '60px' }}
                  onClick={() =>
                    this.setState({
                      is_show: false,
                    })
                  }
                />
                <div className="autocomplete-items">
                  {location_items.length || activity_items.length ? (
                    <>
                      {location_items.map((v, i) => {
                        return (
                          <div
                            key={`location${i}`}
                            className="autocomplete-item location"
                          >
                            <Link
                              as={`/things-to-do/${v.custom_url}`}
                              href={`/things-to-do/[slug]`}
                            >
                              <a>
                                <div className="item-inner">
                                  <div className="item-content">
                                    <LocationMarker></LocationMarker>
                                    <div className="title">
                                      {parse(
                                        v.type === 'country'
                                          ? `<em>${v.country_name}</em>`
                                          : `${v.country_name} &gt; <em>${v.city_name}</em>`
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </Link>
                          </div>
                        );
                      })}
                      {activity_items.map((v, i) => {
                        return (
                          <div
                            key={`activity${i}`}
                            className="autocomplete-item"
                          >
                            <Link
                              as={`/experience/detail/${v.custom_url}`}
                              href={`/experience/detail/[slug]`}
                            >
                              <a>
                                <div className="item-inner">
                                  <div className="item-content">
                                    {v.available !== 1 ? (
                                      <div className="item-col">
                                        <span className="status">
                                          {v.available === 2
                                            ? 'Arriving Soon'
                                            : v.available === 0
                                            ? 'Sold Out'
                                            : null}
                                        </span>
                                      </div>
                                    ) : null}
                                    <div className="item-col">
                                      <div className="title">
                                        {parse(v.title_highlight || v.title)}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="city">{v.country}</div>
                                </div>
                              </a>
                            </Link>
                          </div>
                        );
                      })}
                      <Link
                        href="/search/[slug]"
                        as={`/search/activities?keyword=${keyword}`}
                      >
                        <a className="autocomplete-detail">
                          {t('find-more', { keyword: keyword })}
                          <Chevron right />
                        </a>
                      </Link>
                    </>
                  ) : (
                    <div className="autocomplete-item empty">
                      {t('try-again')}
                    </div>
                  )}
                </div>
              </>
            ) : null
          ) : null}
        </fieldset>
      </Search>
    );
  }
}

export default connect(state => state, actions)(withTranslation(SearchBox, "common"));
