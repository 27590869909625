import React from 'react';
import styled from 'styled-components';
import theme from '/style/theme';

const Wrapper = styled.button<{ white?: boolean }>`
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0;
  font-size: 14px;
  line-height: 38px;
  color: ${props => props.theme.gy};
  span {
    float: left;
    vertical-align: top;
    svg {
      width: 38px;
      height: 38px;
    }
  }
  ${props =>
    props.white &&
    `
  color: #fff;
    svg {                 
      .icon {
        fill: #fff;
      }
    }
   @media ${theme.sm} {
    color: ${props.theme.gy};
    svg {                 
      .icon {
        fill: ${props.theme.gy};
      }
    }
   }
  `}
`;

interface Props extends React.HTMLAttributes<HTMLElement> {
  white?: boolean;
}

const ButtonBack: React.FC<Props> = ({ white, children = 'Back', ...rest }) => {
  return (
    <Wrapper white={white} {...rest}>
      <span
        dangerouslySetInnerHTML={{
          __html: require('../../public/images/ui/chevron-left.svg?include'),
        }}
      />
      {children}
    </Wrapper>
  );
};

export default ButtonBack;
