import Link from 'next/link';
import styled from 'styled-components';
import theme from '/style/theme';

const Wrapper = styled.div`
  a {
    display: block;
    position: relative;
    height: 50px;
    padding: 15px 20px;
    background-color: #fff;
    font-size: 15px;
    color: ${theme.mid_gy};
    line-height: 1.2;
    border-top: 1px solid ${theme.light_gy2};
    .currency, .language {
      color: ${theme.brand};
      float: right;
    }
    ${props =>
      props.icon &&
      `
      padding-left: 58px;
      border-top: 0;
      border-bottom: 1px solid ${theme.light_gy2};
      color: ${theme.brand};
      &:hover {
        color: ${theme.brand};
      }
      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: 10px;
        width: 38px;
        height: 38px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(${
          props.home
            ? require('/public/images/header/header-ico-home.svg')
            : require('/public/images/header/header-ico-user.svg')
        });
      }
    `}
  }
`;

const MenuItemMobile = ({ icon, home, user, href, onClick, children }) => {
  return (
    <Wrapper home={home} user={user} icon={icon}>
      {href ? (
        <Link href={href} prefetch={false}>
          <a>{children}</a>
        </Link>
      ) : (
        <a onClick={onClick}>{children}</a>
      )}
    </Wrapper>
  );
};

export default MenuItemMobile;
