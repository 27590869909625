import React, {Component} from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import actions from '/store/actions';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Close from "/components/ui/Close";
import Button from "/components/ui/Button";
import { clearfix } from "/style/mixin";
import Textarea from "/components/ui/Textarea";
import Textbox from "/components/ui/Textbox";
import OpeningHoursSelect from "/components/Spot/OpeningHoursSelect";
import StarRatingSelect from "/components/Spot/StarRatingSelect";
import Checkbox from "/components/ui/Checkbox";
import Radio from "/components/ui/Radio";
import CheckInOutSelect from "/components/Spot//CheckInOutSelect";
import AgeLimitInput from "/components/Spot/AgeLimitInput";
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api'
import Geocode from "react-geocode";
import getConfig from 'next/config';
import { prepareText, addNewline } from "/utils/formTextProcessing";
import axios from "axios";
import withTranslation from 'next-translate/withTranslation';
import Trans from 'next-translate/Trans';
const { publicRuntimeConfig } = getConfig();

const Wrapper = styled.div`
  background-color: #fff;
  position: relative;
  .close-button{
    top:10px;
    right:10px;
  }
  .back-btn{
    float:left;
    cursor:pointer;
    line-height:38px;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: ${theme.trazy_og};
    margin-top:6px;
    img{
      display: inline-block;
      vertical-align:top;
    }
  }
  .modal-title{
    font-size: 16px;
    font-weight: ${theme.bold};
    line-height: 1.56;
    letter-spacing: -0.3px;
    color: ${theme.dark_gy};
    text-align:center;
    margin-bottom:20px;
  }
  textarea{
    border-radius: 5px;
    border: 1px solid ${theme.line_gy};
    padding: 15px;
    width: 100%;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.2px;
    color: ${theme.mid_gy};
    &.large-textarea{
      height: 250px;
    }
    &.address-textarea{
      width: 215px;
      height: 215px;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: -0.2px;
      display: inline-block;
      margin-right: 10px;
    }
  }
  .btn-wrapper{
    margin-top:20px;
  }
  .submit-btn{
    float:right;
  }
  .field-select{
    padding: 30px 50px 20px 50px;
  }
  .input-modal{
    padding: 30px;
  }
  .option-item {
    margin-bottom: 15px;
    &.inline-block {
      display: inline-block;
      margin-right: 27px;
      &:nth-child(3n+1) {
        width: 135px;
      }
      &:nth-child(3n+2) {
        width: 126px;
      }
      &:nth-child(3n) {
        margin-right: 0px;
        width: 125px;
      }
      label{
        margin-right: 0px;
      }
    }
    &.parking-option-item {
      input[type="radio"] + span {
        line-height: 1.6;
        &:before{
          top: 4px;
        }
        &:after{
          top: 8px;
        }
      }
    }
  }
  .suboption-wrapper{
    display: inline-block;
    vertical-align: top;
    margin-left: 56px;
    width: 186px;
    .suboption-item{
      display: inline-block;
      width: calc(50% - 15px);
      &:nth-child(odd) {
        margin-right: 30px;
      }
      &:nth-child(even) {
        margin-right: 0px;
      }
      label{
        margin-right: 0px;
      }
    }
  }
  .guide-label{
    font-size: 15px;
    font-weight: ${theme.bold};
    line-height: 1.6;
    letter-spacing: -0.2px;
    color: ${theme.mid_gy};
    margin-bottom:7px;
  }
  .transport-wrapper {
    display: inline-block;
    vertical-align:top;
    width: calc(50% - 5px);
    &:nth-child(odd) {
      margin-right: 10px;
    }
    label {
      height: 38px;
      display: block;
      img{
        display:inline-block;
        vertical-align:top;
      }
      span{
        display:inline-block;
        vertical-align:top;
        font-weight: ${theme.normal};
        line-height: 38px;
      }
    }
    textarea{
      height: 60px;
      margin-bottom: 7px;
      display: block;
    }
  }
  .parking-wrapper{
    margin-top: 23px;
    padding-top: 30px;
    border-top: 1px solid ${theme.line_gy};
    .guide-label{
      margin-top: 20px;
      margin-bottom: 10px;
    }
    textarea{
      height: 100px;
    }
  }
  .address-guide{
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: -0.2px;
    color: ${theme.mid_gy};
  }
  .clearfix {
    ${clearfix}
  }
`;

const FieldButton = styled.div`
  display: inline-block;
  cursor: pointer;
  vertical-align:top;
  width: 195px;
  min-height: 50px;
  border-radius: 5px;
  background-image: linear-gradient(to right, ${theme.light_og} 0%, ${theme.pastel_og} 100%);
  color: #FFF;
  font-size: 15px;
  font-weight: ${theme.medi};
  letter-spacing: -0.2px;
  padding: 6px 5px;
  margin-bottom:10px;
  &:nth-child(even) {
    margin-right:10px;
  }
  img{
    display: inline-block;
    vertical-align:top;
  }
  span{
    width:calc(100% - 48px);
    display: inline-block;
    vertical-align:top;
    line-height: 1.6;
    margin-left: 10px;
    padding: 7px 0px;
  }
`;

const MapWrapper = styled.section`
  height: 215px;
  width: 215px;
  display: inline-block;
`;

Modal.setAppElement('#__next');

Geocode.setApiKey("AIzaSyAKx_iGK-QFO8yj_gUepARjalW7x58zZCc");

class ModalSpot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 500,
      description: '',
      phone_number:'',
      website:'',
      has_age_limit: 0,
      age_limit: '',
      opening_hours: [[],[],[],[],[],[],[]],
      day_range_selected: '',
      day_selected: [false,false,false,false,false,false,false],
      open_hour: "00",
      open_minute: "00",
      close_hour: "00",
      close_minute: "00",
      closed_days:'',
      admissions:'',
      main_menu:'',
      star_rating: 0,
      checkin_hour:'',
      checkin_minute:'',
      checkout_hour:'',
      checkout_minute:'',
      price:'',
      price_range:'',
      payment_option:[],
      service_option:[],
      public_transport:'',
      discount_offers: '',
      transport_bus: '',
      transport_metro:'',
      transport_train:'',
      transport_car:'',
      parking_availability: '',
      parking_type: [],
      parking_more: '',
      center: {
        lat: 0,
        lng: 0
      },
      zoom: 0,
      show_marker: false,
      marker_position: null,
      address: "",
      prev_address: "",
      address_from_pin: false,
      parking_service: [],
      payment_service: [],
      hotel_service: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.getNewAddress = this.getNewAddress.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    this.updateState = this.updateState.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (name == 'has_age_limit' && value == 0) {
      this.setState({
        age_limit: ''
      });
    }
  }
  handleSelectChange(name, e) {
    this.setState({
      [name]: e.value
    });
  }
  handleCheckboxChange(event) {
    const target = event.target;
    const name = target.name;
    const value = parseInt(target.value);
    let values = this.state[name];
    const index = values.indexOf(value);
    if (index >= 0) {
      values.splice(index, 1);
    } else {
      values.push(value);
    }
    this.setState({
      [name]: values
    });
  }
  handleRadioButtonChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;
    const cur_value = this.state[name];
    if (cur_value == value) {
      value = '';
    }
    this.setState({
      [name]: value
    });
    if (name == 'parking_availability' && value != 1) {
      this.setState({
        parking_type: []
      });
    }
  }
  updateState(name, values) {
    this.setState({
      [name]: values
    });
  }
  mapClicked(e) {
    this.setState({center: e.latLng, show_marker: true, marker_position: e.latLng});
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      response => {
        const new_address = response.results[0].formatted_address;
        this.setState({
          address: new_address,
          prev_address: new_address,
          address_from_pin: true,
        });
      },
      error => {
        console.error(error);
      }
    );
  }
  handleKeyPress(e) {
    if(e.key === 'Enter'){
      this.getNewAddress();
    }
  }
  getNewAddress() {
    const { address, address_from_pin, prev_address } = this.state;
    if (address !== "") {
      if (!address_from_pin || address !== prev_address) {
        Geocode.fromAddress(address).then(
          response => {
            const {lat, lng} = response.results[0].geometry.location;
            this.setState({
              center: {
                lat: lat,
                lng: lng,
              },
              marker_position: {
                lat: lat,
                lng: lng,
              },
              zoom: 15,
              show_marker: true,
            });

            Geocode.fromLatLng(lat, lng).then(
              response => {
                const new_address = response.results[0].formatted_address;
                const {lat: new_lat, lng: new_lng} = response.results[0].geometry.location;
                this.setState({
                  center: {
                    lat: new_lat,
                    lng: new_lng,
                  },
                  marker_position: {
                    lat: new_lat,
                    lng: new_lng,
                  },
                  address: new_address,
                  prev_address: new_address,
                  address_from_pin: false,
                  zoom: 15,
                  show_marker: true,
                });
              },
              error => {
                console.error(error);
              }

            );
          },
          error => {
            this.setState({
              center: {
                lat: 0,
                lng: 0,
              },
              marker_position: null,
              zoom: 0,
              show_marker: false
            });
            alert("Geocode was not successful for the following reason: " + error);
          }
        );
      }
    } else {
      this.setState({
        center: {
          lat: 0,
          lng: 0,
        },
        marker_position: null,
        zoom: 0,
        show_marker: false
      });
    }
  }
  submitUpdate() {
    const { authentication, changeSweetAlert, changeLoading, data } = this.props;
    const { type, initial_value, onUpdateSuccess } = data;
    const { spot_idx } = initial_value;

    if (!authentication.user) {
      changeSweetAlert('Oops! Please login first.');
      return;
    }

    const params = {};
    if (type == 'fact') {
      const { description } = this.state;
      params.description = addNewline(description);
    } else if (type == 'address') {
      const { address, marker_position } = this.state;
      const { lat, lng } = marker_position;
      params.address = address;
      params.latitude = lat;
      params.longitude = lng;
    } else if (type == 'phone_number') {
      const { phone_number } = this.state;
      params.phone = phone_number;
    } else if (type == 'website') {
      const { website } = this.state;
      params.website = website;
    } else if (type == 'age_limit') {
      const { has_age_limit, age_limit } = this.state;
      if (parseInt(has_age_limit) == 1 && (!age_limit || parseInt(age_limit) <= 0)) {
        changeSweetAlert('Please set the age limit.');
        return;
      }
      params.age_limit = age_limit;
    } else if (type == 'admissions') {
      const { admissions } = this.state;
      params.admission = addNewline(admissions);
    } else if (type == 'main_menu') {
      const { main_menu } = this.state;
      params.main_menu = addNewline(main_menu);
    } else if (type == 'star_rating') {
      const { star_rating } = this.state;
      params.star = star_rating;
    } else if (type == 'price') {
      const { price } = this.state;
      params.price = addNewline(price);
    } else if (type == 'price_range') {
      const { price_range } = this.state;
      params.price = addNewline(price_range);
    } else if (type == 'payment') {
      const { payment_option } = this.state;
      params.payment = payment_option.reduce((a, b) => a + b, 0);
    } else if (type == 'service') {
      const { service_option } = this.state;
      params.service = service_option.reduce((a, b) => a + b, 0);
    } else if (type == 'public_transport') {
      const { public_transport } = this.state;
      params.public_transport = addNewline(public_transport);
    } else if (type == 'discount_offers') {
      const { discount_offers } = this.state;
      params.discount = addNewline(discount_offers);
    } else if (type == 'directions') {
      const { transport_bus, transport_metro, transport_train, transport_car, parking_availability, parking_type, parking_more } = this.state;
      params.transport_bus = addNewline(transport_bus);
      params.transport_metro = addNewline(transport_metro);
      params.transport_car = addNewline(transport_car);
      params.transport_train = addNewline(transport_train);
      if (parking_availability == '') {
        params.parking = null;
      } else if (parking_availability == 1) {
        params.parking = parking_type.reduce((a, b) => a + b, 0);
      } else if (parking_availability == 0) {
        params.parking = 1;
      }
      params.parking_more = addNewline(parking_more);
    } else if (type == 'opening_hours') {
      const { opening_hours, closed_days } = this.state;
      params.open_time = opening_hours.map((cur) => {
        const time_arr = cur.map((cur_time) => {
          return cur_time.open_hour + cur_time.open_minute + cur_time.close_hour + cur_time.close_minute;
        });
        return time_arr;
      });
      params.closed = addNewline(closed_days);
    } else if (type == 'checkinout') {
      const {checkin_hour, checkin_minute, checkout_hour, checkout_minute} = this.state;
      params.open_time = [];
      if (checkin_hour && checkin_minute && checkout_hour && checkout_minute) {
        params.open_time[0] = [checkin_hour + checkin_minute + checkout_hour + checkout_minute];
      } else if (checkin_hour || checkin_minute || checkout_hour || checkout_minute) {
        changeSweetAlert('Please set a valid check in & out time.');
        return;
      }
    }

    changeLoading(true);
    const config = { headers: { Authorization: 'bearer ' + authentication.token } };
    let endpoint = `${publicRuntimeConfig.API_PATH}/spot/${spot_idx}`;
    if (type == 'opening_hours' || type == 'checkinout') {
      endpoint += '/open_time'
    }
    axios
      .post(
        endpoint,
        params,
        config
      )
      .then(res => {
        onUpdateSuccess(res.data);
      })
      .catch(err => {
        changeLoading(false);
        const response = err.response;
        if (response.data.detail) {
          const detail = response.data.detail;
          if (detail === 'Invalid authentication') {
            changeSweetAlert('Oops! Please login first.');
            return;
          } else {
            changeSweetAlert('Oops! Edit info failed.\nPlease wait a moment and try again.');
            return;
          }
        } else {
          changeSweetAlert('Oops! Edit info failed.\nPlease wait a moment and try again.');
          return;
        }
      });
  }
  componentDidMount() {}
  componentDidUpdate(prevProps) {
    const { data } = this.props;
    const { t } = this.props.i18n;
    const parking_service = t('parking-service', {}, { returnObjects: true });
    const payment_service = t('payment-service', {}, { returnObjects: true });
    const hotel_service = t('hotel-service', {}, { returnObjects: true });
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      const { initial_value } = data;
      if (initial_value) {
        const { category_idx, description, phone, website, agelimit, time, closed, admission, mainmenu, star, price, payment, service, publictransp, discount, transp_bus, transp_metro, transp_car, transp_train, parking, parking_more, latitude, longitude, address } = initial_value;
        const opening_hours = [[],[],[],[],[],[],[]];
        let checkin_hour = '';
        let checkin_minute = '';
        let checkout_hour = '';
        let checkout_minute = '';
        if (time && time.length) {
          if (category_idx == 5) {
            checkin_hour = time[0].open.substr(0, 2);
            checkin_minute = time[0].open.substr(2, 2);
            checkout_hour = time[0].close.substr(0, 2);
            checkout_minute = time[0].close.substr(2, 2);
          } else {
            time.map((cur) => {
              const cur_open_hour = cur.open.substr(0, 2);
              const cur_open_minute = cur.open.substr(2, 2);
              const cur_close_hour = cur.close.substr(0, 2);
              const cur_close_minute = cur.close.substr(2, 2);
              opening_hours[cur.day].push({
                open_hour: cur_open_hour,
                open_minute: cur_open_minute,
                close_hour: cur_close_hour,
                close_minute: cur_close_minute,
                label: cur_open_hour + ' : ' + cur_open_minute + ' ~ ' + cur_close_hour + ' : ' + cur_close_minute
              })
            })
          }
        }
        const payment_option = [];
        if (payment) {
          payment_service.map((cur, i) => {
            const cur_val = Math.pow(2, payment_service.length - i - 1);
            if (payment & cur_val) payment_option.push(cur_val);
          });
        }
        const service_option = [];
        if (service) {
          hotel_service.map((cur, i) => {
            const cur_val = Math.pow(2, hotel_service.length - i);
            if (service & cur_val) service_option.push(cur_val);
          });
        }
        let parking_availability = '';
        const parking_type = [];
        if (parking) {
          parking_service.map((cur, i) => {
            const cur_val = Math.pow(2, parking_service.length - i);
            if (parking & cur_val) parking_type.push(cur_val);
          });
        }
        if (parking_type.length) {
          parking_availability = 1
        } else if (parking == 1) {
          parking_availability = 0;
        }
        this.setState({
          description: prepareText(description),
          phone_number: phone,
          website: website,
          has_age_limit: agelimit && agelimit > 0 ? 1 : 0,
          age_limit: agelimit && agelimit > 0 ? agelimit : '',
          opening_hours: opening_hours,
          day_range_selected: '',
          day_selected: [false,false,false,false,false,false,false],
          open_hour: "00",
          open_minute: "00",
          close_hour: "00",
          close_minute: "00",
          closed_days: prepareText(closed),
          admissions: prepareText(admission),
          main_menu: prepareText(mainmenu),
          star_rating: star,
          checkin_hour: checkin_hour,
          checkin_minute: checkin_minute,
          checkout_hour: checkout_hour,
          checkout_minute: checkout_minute,
          price: prepareText(price),
          price_range: prepareText(price),
          payment_option: payment_option,
          service_option: service_option,
          public_transport: prepareText(publictransp),
          discount_offers: prepareText(discount),
          transport_bus: prepareText(transp_bus),
          transport_metro: prepareText(transp_metro),
          transport_train: prepareText(transp_train),
          transport_car: prepareText(transp_car),
          parking_availability: parking_availability,
          parking_type: parking_type,
          parking_more: prepareText(parking_more),
          center: {
            lat: latitude,
            lng: longitude,
          },
          zoom: 15,
          show_marker: true,
          marker_position: {
            lat: latitude,
            lng: longitude,
          },
          address: address,
          prev_address: address,
          address_from_pin: false,
          parking_service: parking_service,
          payment_service: payment_service,
          hotel_service: hotel_service,
        });
      }
    }
  }
  render() {
    const { data, changeModalSpot } = this.props;
    const { t } = this.props.i18n;
    const { initial_value } = data;
    const { category_idx: category } = initial_value;
    const { maxWidth, description, center, zoom, show_marker, marker_position, address, phone_number, website, has_age_limit, age_limit, opening_hours, day_range_selected, day_selected, open_hour, open_minute, close_hour, close_minute, closed_days, admissions, main_menu, star_rating, checkin_hour, checkin_minute, checkout_hour, checkout_minute, price, price_range, payment_option, service_option, public_transport, discount_offers, transport_bus, transport_metro, transport_train, transport_car, parking_availability, parking_type, parking_more, parking_service, payment_service, hotel_service } = this.state;
    return (
      <Modal
        isOpen={data.show}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            outline: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflowX: 'hidden',
            padding: '0px',
            backgroundColor: 'transparent',
            border: 0,
            maxWidth: maxWidth,
            width: '100%',
            borderRadius: '5px',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
            overflow: 'visible'
          },
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 200,
          },
        }}
      >
        <Wrapper>
          <Close
            className="close-button"
            backgroundColor={theme.darker_gray}
            onClick={() => {
              this.props.changeModalSpot({
                show: false,
              });
            }}
          />
          {data.type === 'basic' ? (
            <div className="field-select">
              <div className="modal-title">{t('please-edit')}</div>
              <FieldButton onClick={() =>
                  changeModalSpot({
                    type: 'fact',
                    show: true,
                  })
                }><img src={require("/public/images/spot/ic-spot-thefact-wh.svg")} /><span>{t('fact')}</span>
              </FieldButton>
              <FieldButton onClick={() =>
                  changeModalSpot({
                    type: 'address',
                    show: true,
                  })
                }><img src={require("/public/images/spot/ic-spot-address-wh.svg")} /><span>{t('address')}</span>
              </FieldButton>
              <FieldButton onClick={() =>
                  changeModalSpot({
                    type: 'phone_number',
                    show: true,
                  })
                }><img src={require("/public/images/spot/ic-spot-phone-num-wh.svg")} /><span>{t('phone-number')}</span>
              </FieldButton>
              <FieldButton onClick={() =>
                  changeModalSpot({
                    type: 'website',
                    show: true,
                  })
                }><img src={require("/public/images/spot/ic-spot-web-wh.svg")} /><span>{t('website')}</span>
              </FieldButton>
              {(category == 0 || category == 3) &&
              <FieldButton onClick={() =>
                changeModalSpot({
                  type: 'age_limit',
                  show: true,
                })
              }><img src={require("/public/images/spot/ic-spot-agelimit-wh.svg")}/><span>{t('age-limit')}</span>
              </FieldButton>
              }
              {(category == 0 || category != 5) &&
              <FieldButton onClick={() =>
                changeModalSpot({
                  type: 'opening_hours',
                  show: true,
                })
              }><img src={require("/public/images/spot/ic-spot-time-wh.svg")}/><span>{t('opening-hours-label')}</span>
              </FieldButton>
              }
              {(category == 0 || category == 1) &&
              <FieldButton onClick={() =>
                changeModalSpot({
                  type: 'admissions',
                  show: true,
                })
              }><img src={require("/public/images/spot/ic-spot-admissions-wh.svg")}/><span>{t('admissions')}</span>
              </FieldButton>
              }
              {(category == 0 || category == 2) &&
              <FieldButton onClick={() =>
                changeModalSpot({
                  type: 'main_menu',
                  show: true,
                })
              }><img src={require("/public/images/spot/ic-spot-mainmenu-wh.svg")}/><span>{t('main-menu')}</span>
              </FieldButton>
              }
              {(category == 0 || category == 5) &&
              <FieldButton onClick={() =>
                changeModalSpot({
                  type: 'star_rating',
                  show: true,
                })
              }><img src={require("/public/images/spot/ic-spot-star-wh.svg")}/><span>{t('hotel-class')}</span>
              </FieldButton>
              }
              {(category == 0 || category == 5) &&
              <FieldButton onClick={() =>
                changeModalSpot({
                  type: 'checkinout',
                  show: true,
                })
              }><img src={require("/public/images/spot/ic-spot-checkinout-wh.svg")}/><span>{t('check-in-out-label')}</span>
              </FieldButton>
              }
              {(category == 0 || category == 2 || category == 3) &&
              <FieldButton onClick={() =>
                changeModalSpot({
                  type: 'price',
                  show: true,
                })
              }><img src={require("/public/images/spot/ic-spot-price-wh.svg")}/><span>{t('price')}</span>
              </FieldButton>
              }
              {(category == 0 || category == 4 || category == 5) &&
              <FieldButton onClick={() =>
                changeModalSpot({
                  type: 'price_range',
                  show: true,
                })
              }><img src={require("/public/images/spot/ic-spot-price-wh.svg")}/><span>{t('price-range')}</span>
              </FieldButton>
              }
              <FieldButton onClick={() =>
                  changeModalSpot({
                    type: 'payment',
                    show: true,
                  })
                }><img src={require("/public/images/spot/ic-spot-payment-wh.svg")} /><span>{t('payment')}</span>
              </FieldButton>
              {(category == 0 || category == 5) &&
              <FieldButton onClick={() =>
                changeModalSpot({
                  type: 'service',
                  show: true,
                })
              }><img src={require("/public/images/spot/ic-spot-service-wh.svg")}/><span>{t('service')}</span>
              </FieldButton>
              }
              {(category == 0 || category == 5) &&
              <FieldButton onClick={() =>
                changeModalSpot({
                  type: 'public_transport',
                  show: true,
                })
              }><img
                src={require("/public/images/spot/ic-spot-metro-wh.svg")}/><span>{t('public-transport')}</span>
              </FieldButton>
              }
              {(category == 0 || category == 4) &&
              <FieldButton onClick={() =>
                changeModalSpot({
                  type: 'discount_offers',
                  show: true,
                })
              }><img src={require("/public/images/spot/ic-spot-discount-wh.svg")}/><span>{t('discount-offers')}</span>
              </FieldButton>
              }
              <FieldButton onClick={() =>
                  changeModalSpot({
                    type: 'directions',
                    show: true,
                  })
                }><img src={require("/public/images/spot/ic-spot-address-wh.svg")} /><span>{t('directions')}</span>
              </FieldButton>
            </div>
          ) : data.type === 'fact' ? (
            <div className="input-modal">
              <div className="modal-title">{t('fact')}</div>
              <Textarea className="large-textarea" name="description" value={description} onChange={this.handleInputChange}></Textarea>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'address' ? (
            <div className="input-modal">
              <div className="modal-title">{t('address')}</div>
              <Textarea className="address-textarea" name="address" value={address} onChange={this.handleInputChange} onBlur={this.getNewAddress} onKeyPress={this.handleKeyPress}></Textarea>
              <MapWrapper>
                <LoadScriptNext googleMapsApiKey="AIzaSyAKx_iGK-QFO8yj_gUepARjalW7x58zZCc">
                  <GoogleMap id="map" center={center} zoom={zoom} options={{mapTypeControl: false, streetViewControl: false}}
                    mapContainerStyle={{
                      height: "215px",
                      width: "215px"
                    }}
                    onClick={e => this.mapClicked(e)}
                    ref={(map) => this.map = map}
                  >
                    {show_marker && <Marker position={marker_position} icon={`/images/ui/pin-ic-default-${category>0?category:1}.png`}></Marker>}
                  </GoogleMap>
                </LoadScriptNext>
              </MapWrapper>
              <div className="address-guide">
                <Trans
                  i18nKey="spot:map-description"
                  components={{
                    br: <br />
                  }}
                ></Trans>
              </div>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'phone_number' ? (
            <div className="input-modal">
              <div className="modal-title">{t('phone-number')}</div>
              <Textbox type="text" name="phone_number" value={phone_number} onChange={this.handleInputChange}></Textbox>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'website' ? (
            <div className="input-modal">
              <div className="modal-title">{t('website')}</div>
              <Textbox type="text" name="website" value={website} onChange={this.handleInputChange}></Textbox>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'age_limit' ? (
            <div className="input-modal">
              <div className="modal-title">{t('age-limit')}</div>
              <AgeLimitInput has_age_limit={has_age_limit} age_limit={age_limit} onHandleInputChange={this.handleInputChange}></AgeLimitInput>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'opening_hours' ? (
            <div className="input-modal">
              <div className="modal-title">{t('opening-hours-label')}</div>
              <OpeningHoursSelect
                inside_modal={true}
                onHandleInputChange={this.handleInputChange}
                onUpdateState={this.updateState}
                day_range_selected={day_range_selected}
                day_selected={day_selected}
                open_hour={open_hour}
                open_minute={open_minute}
                close_hour={close_hour}
                close_minute={close_minute}
                opening_hours={opening_hours}
                closed_days={closed_days}
              ></OpeningHoursSelect>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'admissions' ? (
            <div className="input-modal">
              <div className="modal-title">{t('admissions')}</div>
              <Textarea className="large-textarea" name="admissions" value={admissions} onChange={this.handleInputChange}></Textarea>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'main_menu' ? (
            <div className="input-modal">
              <div className="modal-title">{t('main-menu')}</div>
              <Textarea className="large-textarea" name="main_menu" value={main_menu} onChange={this.handleInputChange}></Textarea>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'star_rating' ? (
            <div className="input-modal">
              <div className="modal-title">{t('hotel-class')}</div>
              <StarRatingSelect
                inside_modal={true}
                value={star_rating}
                onChange={e =>
                  this.handleSelectChange("star_rating", e)
              }></StarRatingSelect>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'checkinout' ? (
            <div className="input-modal">
              <div className="modal-title">{t('check-in-out-label')}</div>
              <CheckInOutSelect
                inside_modal={true}
                checkin_hour={checkin_hour}
                checkin_minute={checkin_minute}
                checkout_hour={checkout_hour}
                checkout_minute={checkout_minute}
                onUpdateState={this.updateState}
              ></CheckInOutSelect>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'price' ? (
            <div className="input-modal">
              <div className="modal-title">{t('price')}</div>
              <Textarea className="large-textarea" name="price" value={price} onChange={this.handleInputChange}></Textarea>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'price_range' ? (
            <div className="input-modal">
              <div className="modal-title">{t('price-range')}</div>
              <Textarea className="large-textarea" name="price_range" value={price_range} onChange={this.handleInputChange}></Textarea>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'payment' ? (
            <div className="input-modal">
              <div className="modal-title">{t('payment')}</div>
              <div className="option-wrapper">
                {
                  payment_service.map((cur, i) => {
                    const cur_val = Math.pow(2, payment_service.length - i - 1);
                    return <div className="option-item" key={`payment-${i}`}><Checkbox value={cur_val} name="payment_option" defaultChecked={payment_option.includes(cur_val)} onChange={this.handleCheckboxChange}>{cur}</Checkbox></div>
                  })
                }
              </div>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'service' ? (
            <div className="input-modal">
              <div className="modal-title">{t('service')}</div>
              <div className="option-wrapper">
                {
                  hotel_service.map((cur, i) => {
                    const cur_val = Math.pow(2, hotel_service.length - i);
                    return <div className="option-item inline-block"  key={`service-${i}`}><Checkbox value={cur_val} name="service_option" defaultChecked={service_option.includes(cur_val)} onChange={this.handleCheckboxChange}>{cur}</Checkbox></div>
                  })
                }
              </div>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'public_transport' ? (
            <div className="input-modal">
              <div className="modal-title">{t('public-transport')}</div>
              <Textarea className="large-textarea" name="public_transport" value={public_transport} onChange={this.handleInputChange}></Textarea>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'discount_offers' ? (
            <div className="input-modal">
              <div className="modal-title">{t('discount-offers')}</div>
              <Textarea className="large-textarea"  name="discount_offers" value={discount_offers} onChange={this.handleInputChange}></Textarea>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>Done</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : data.type === 'directions' ? (
            <div className="input-modal">
              <div className="modal-title">{t('directions')}</div>
              <div className="guide-label">{t('ways-to-get-here')}</div>
              <div className="transport-wrapper">
                <label htmlFor="transport_bus"><img src={require("/public/images/spot/ic-spot-bus.svg")} /><span>{t('bus')}</span></label>
                <Textarea name="transport_bus" value={transport_bus} onChange={this.handleInputChange}></Textarea>
              </div>
              <div className="transport-wrapper">
                <label htmlFor="transport_metro"><img src={require("/public/images/spot/ic-spot-metro.svg")} /><span>{t('metro')}</span></label>
                <Textarea name="transport_metro" value={transport_metro} onChange={this.handleInputChange}></Textarea>
              </div>
              <div className="transport-wrapper">
                <label htmlFor="transport_train"><img src={require("/public/images/spot/ic-spot-train.svg")} /><span>{t('train')}</span></label>
                <Textarea name="transport_train" value={transport_train} onChange={this.handleInputChange}></Textarea>
              </div>
              <div className="transport-wrapper">
                <label htmlFor="transport_car"><img src={require("/public/images/spot/ic-spot-car.svg")} /><span>{t('car')}</span></label>
                <Textarea name="transport_car" value={transport_car} onChange={this.handleInputChange}></Textarea>
              </div>
              <div className="parking-wrapper">
                <div className="modal-title">{t('parking-label')}</div>
                <div className="option-item parking-option-item">
                  <Radio value={1} name="parking_availability" checked={parseInt(parking_availability) === 1} onChange={() => {return false;}} onClick={this.handleRadioButtonChange}>{t('available')}</Radio>
                  <div className="suboption-wrapper">
                    {
                      parking_service.map((cur, i) => {
                        const cur_val = Math.pow(2, parking_service.length - i);
                        return <div className="suboption-item" key={`parking-${i}`}><Checkbox value={cur_val} name="parking_type" disabled={parking_availability == 0} defaultChecked={parking_type.includes(cur_val)} onChange={this.handleCheckboxChange}>{cur}</Checkbox></div>
                      })
                    }
                  </div>
                </div>
                <div className="option-item parking-option-item">
                  <Radio value={0} name="parking_availability" checked={parseInt(parking_availability) === 0} onChange={() => {return false;}} onClick={this.handleRadioButtonChange}>{t('not-available')}</Radio>
                </div>
                <div className="guide-label">{t('parking-more')}</div>
                <Textarea name="parking_more" value={parking_more} onChange={this.handleInputChange}></Textarea>
              </div>
              <div className="btn-wrapper">
                <div className="back-btn" onClick={() =>
                    changeModalSpot({
                      type: 'basic',
                      show: true,
                    })
                  }><img src={require("/public/images/spot/og-left.svg")} />{t('back')}</div>
                <Button className="submit-btn" font_size="15px" font_weight={500} padding="13px 50px" line_height={1.6} onClick={this.submitUpdate}>{t('done')}</Button>
                <div className="clearfix"></div>
              </div>
            </div>
          ) : null}
        </Wrapper>
      </Modal>
    );
  }
}

export default connect(state => state, actions)(withTranslation(ModalSpot, 'spot'));
