import { connect } from 'react-redux';
import actions from '/store/actions';
import { PhotoSwipe } from 'react-photoswipe';

const PhotoSwipeWrapper = ({ data, changePhotoSwipe }) => {
  return (
    <PhotoSwipe
      isOpen={data.show}
      items={data.items}
      options={{
        index: data.index,
        closeOnScroll: false,
        history: false,
      }}
      afterChange={data.afterChange}
      beforeChange={data.beforeChange}
      onClose={() => {
        if (data.onClose) {
          data.onClose();
        }
        changePhotoSwipe({
          show: false,
        });
      }}
    />
  );
};

export default connect(null, {
  changePhotoSwipe: actions.changePhotoSwipe,
})(PhotoSwipeWrapper);
