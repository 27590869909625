import { Component } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import ExploreByCity from './ExploreByCity';
import SearchBox from './SearchBox';
import MemberStatus from './MemberStatus';
import CurrencyChanger from './CurrencyChanger';
import LanguageChanger from './LanguageChanger';
import Banner from './Banner';
import theme from '/style/theme';
import { connect } from 'react-redux';

const Siteheader = styled.header`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  .translated-ltr & {
    top: 40px;
  }
  .campaign-header-fixed &,
  .event-header-fixed &,
  .country-header-fixed &,
  .em-header-fixed & {
    box-shadow: none;
    border-bottom: 1px solid ${theme.line_gy};
  }
`;
const Brand = styled.div`
  float: left;
  padding-top: 17px;
  padding-left: 20px;
  a {
    display: block;
  }
  @media (min-width: 1180px) {
    margin-right: 20px;
  }
`;
const BrandLogo = styled.h1`
  float: left;
  margin: 0;
  a {
    height: 26px;
    width: 84px;
    background-image: url(${require('/public/images/header/brand.svg')});
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: -9999px;
  }
`;
const Tagline = styled.span`
  display: none;
  position: relative;
  float: left;
  margin-left: 5px;
  margin-top: 6px;
  font-size: 12px;
  font-weight: ${props => props.theme.normal};
  color: ${props => props.theme.brand};
  vertical-align: top;
  letter-spacing: -0.2px;
  @media (min-width: 1180px) {
    display: block;
  }
`;
const AffiliateWrapper = styled.div`
  float: left;
  position: relative;
  padding-left: 22px;
  margin-left: 17px;
  &:before {
    content: '';
    position: absolute;
    left: 3px;
    top: 2px;
    width: 1px;
    height: 20px;
    background-color: ${theme.light_gy3};
  }
`;
const TWayLogo = styled.div`
  width: 55px;
  height: 26px;
  background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/promotion/tway/tway_logo.png');
  display: inline-block;
  position: relative;
  vertical-align: top;
`;
const FlexBox = styled.div`
  display:flex;
`;
const RightMenuWrapper = styled.div`
  flex: 0 0 auto; 
  margin-left:auto;
  padding-left: 10px;
  padding-right: 10px;
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: false,
    };
    this.toggleBanner = this.toggleBanner.bind(this);
  }
  toggleBanner() {
    this.setState(prevState => ({
      banner: !prevState.banner,
    }));
  }
  render() {
    const { keyword, affiliate_partner, authentication, subdomain } = this.props;
    const { banner } = this.state;
    return (
      <>
        <Siteheader id="site-header">
          <Brand>
            <BrandLogo>
              <Link href="/">
                <a>Trazy</a>
              </Link>
              <div className="sr-only">- Book Travel Crazy Things to do in Asia</div>
            </BrandLogo>
            {affiliate_partner === 'tway' ? (
              <AffiliateWrapper>
                <TWayLogo />
              </AffiliateWrapper>
            ) : (
              <Tagline>Your Travel Shop for Asia</Tagline>
            )}
          </Brand>
          { subdomain !== 'business' || (subdomain === 'business' && authentication.user) ? <ExploreByCity /> : null }
          <FlexBox>
            { subdomain !== 'business' || (subdomain === 'business' && authentication.user) ? <SearchBox keyword={keyword} /> : null }
            <div style={{ visibility: "hidden", flexGrow: 1 }}></div>
            <RightMenuWrapper>
              <LanguageChanger />
              <CurrencyChanger />
              <MemberStatus toggleBanner={this.toggleBanner} banner={banner} />
            </RightMenuWrapper>
          </FlexBox>
        </Siteheader>
        {banner ? <Banner /> : null}
      </>
    );
  }
}

export default connect(state => ({
  authentication: state.authentication,
  subdomain: state.subdomain,
}))(Header);
