import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0;
  font-size: 13px;
  font-weight: ${props => props.theme.normal};
  color: ${props => props.theme.gy};
  line-height: 1.38;
`;

const FooterText = ({children, ...rest}) => {
  return (
    <Wrapper {...rest}>{children}</Wrapper>
  )
};

export default FooterText;
