// @ts-nocheck
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import ChevronButton from '/components/ui/ChevronButton';
import BgLayer from '/components/ui/BgLayer';
import CityMenuStyle from './CityMenuStyle';
import theme from 'style/theme';
import Router from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const City = styled.div`
  position: relative;
  float: left;
`;

const ExploreByCity = () => {
  const [opened, toggleOpen] = useState(false);
  const { t } = useTranslation('common');
  useEffect(() => {
    Router.events.on('routeChangeStart', () => toggleOpen(false));
    return () => {
      Router.events.off('routeChangeStart', () => toggleOpen(false));
    };
  }, []);
  return (
      <City>
        <ChevronButton
          active={opened}
          onClick={() => toggleOpen(!opened)}
          active_color={theme.brand}
        >
            {t('header.explore-by-location')}
        </ChevronButton>
        {opened ? (
          <>
            <BgLayer onClick={() => toggleOpen(!opened)} transparent />
            <CityMenuStyle className="city-menu" header hide_other_countries={false} />
          </>
        ) : null}
      </City>
  );
};
export default ExploreByCity;
