import styled from 'styled-components';
import { clearfix } from '/style/mixin';
const Wrapper = styled.div`
  ${clearfix}
  > label {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
`;
const RadioButtonGroup = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

export default RadioButtonGroup;
