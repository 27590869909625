import classnames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import actions from '/store/actions';
import ButtonIncrement from '/components/ui/ButtonIncrement';
import Counter from '/components/ui/Counter';
import Price from '/components/ui/Price';
import styled from 'styled-components';
import theme from '/style/theme';
import { backgroundCover, transition } from '/style/mixin';
import parse from '/utils/parse';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';

const Wrapper = styled.div`
  margin-top: 15px;
  position: relative;
  padding: 15px 20px;
  border: 1px solid ${theme.line_gy};
  border-radius: 5px;
  background-color: #fff;
  &:first-child {
    margin-top: 0;
  }
  .cart-item-header {
    position: relative;
    overflow: hidden;
    padding-left: 80px;
    .item-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      border-radius: 5px;
      ${backgroundCover}
    }
    .item-text {
      .title {
        margin-right: 48px;
        font-size: 15px;
        font-weight: ${theme.bold};
        line-height: 1.53;
        color: ${theme.dark_gy};
      }
      .option-selected {
        margin-top: 5px;
        font-size: 12px;
        color: ${theme.gy};
        line-height: 1.83;
        font-weight: normal;
      }
    }
  }
  .remove-cart-item {
    cursor: pointer;
    display: block;
    text-indent: -9999px;
    position: absolute;
    top: -5px;
    right: -10px;
    width: 38px;
    height: 38px;
    background-image: url(${require('/public/images/ui/ico-delete.svg')});
    background-position: center center;
    background-repeat: no-repeat;
    ${transition()}
    &:hover {
      background-image: url(${require('/public/images/ui/ico-delete_hover.svg')});
    }
  }
  .participants {
    margin-top: 20px;
    border-top: 1px solid ${theme.line_gy};
    border-bottom: 1px solid ${theme.line_gy};
    padding: 10px 0;
    .participant {
      overflow: hidden;
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
      .label {
        float: left;
        font-size: 12px;
        line-height: 29px;
        color: ${theme.mid_gy};
        .label-name {
          font-size: 12px;
          font-weight: ${theme.normal};
          line-height: 29px;
          color: ${theme.gy};
        }
      }
      .quantity {
        float: right;
      }
    }
  }
  .sum {
    margin-top: 10px;
    .sum-row {
      margin-top: 5px;
      overflow: hidden;
      font-size: 14px;
      line-height: 25px;
      color: ${theme.mid_gy};
      font-weight: ${theme.medi};
      &:first-child {
        margin-top: 0;
      }
      .label {
        float: left;
      }
      .value {
        float: right;
      }
      &.discount {
        .label {
          padding: 0 8px;
          background-color: ${theme.trazy_og};
          border-radius: 5px;
          font-size: 13px;
          color: #fff;
          font-weight: ${theme.normal};
        }
      }
      &.selling {
        .value {
          font-size: 16px;
          line-height: 25px;
          font-weight: ${theme.bold};
          color: ${theme.trazy_og};
        }
      }
    }
  }
  &.error {
    border-color: ${theme.trazy_og};
    background-color: ${theme.light_gy2};
    .cart-item-header .item-text .title,
    .cart-item-header .item-text .option-selected,
    .participants .participant .label {
      color: ${theme.light_gy4};
    }
    .error-message {
      background-color: ${theme.trazy_og};
      color: #fff;
      font-size: 14px;
      padding: 9px 20px;
      line-height: 20px;
      margin: 20px -20px -15px;
    }
  }
`;
const ElementCartItem = ({
  limit,
  item_value,
  item_key,
  auto_discount,
  total_price_pre,
  discount,
  total_price,
  currency,
  validateAndRemoveCart,
  changeQuantity,
  error_message,
}) => {
  const { t } = useTranslation('common');
  return (
    <Wrapper className={classnames('cart-item', { limit }, { error: error_message })}>
      <div className="cart-item-header">
        <div
          className="remove-cart-item"
          onClick={() => {
            validateAndRemoveCart(item_key);
          }}
        >
          Remove
        </div>
        <div
          className="item-image"
          style={{
            backgroundImage: `url(${item_value.get('cover_image')})`,
          }}
        ></div>
        <div className="item-text">
          <div className="title">
            <Link href={`/experience/detail/${item_value.get('custom_url')}`}>
              <a target="_blank">
                {parse(item_value.get('title'))}
              </a>
            </Link>
          </div>
          <div className="option-selected">
            <div className="name">{parse(item_value.get('option_name'))}</div>
            <div className="time">
              {t('cart.date-time')} : {moment(item_value.get('reserve_date')).format('MM/DD/YYYY')}
              {item_value.get('has_time_option')
                ? ' ' + moment(item_value.get('reserve_time'), 'HHmm').format('HH:mm')
                : null}
            </div>
          </div>
        </div>
      </div>
      <div className="cart-item-body">
        <div className="participants">
          {item_value.get('participant_option').map((w, j) => {
            // iterating options
            return (
              <div className="participant" key={`price${j}`}>
                <div className="label">
                  <span className="label-name">{parse(w.get('participant_type'))}</span>
                </div>
                <div className="quantity">
                  <ButtonIncrement
                    onClick={() =>
                      changeQuantity({
                        target: w,
                        type: '-',
                        idx_arr: [item_key, 'participant_option', j],
                      })
                    }
                    limit={limit || error_message}
                    type="subtract"
                    small
                  >
                    Subtract
                  </ButtonIncrement>
                  <Counter small limit={limit || error_message}>
                    {w.get('qty')}
                  </Counter>
                  <ButtonIncrement
                    onClick={() =>
                      changeQuantity({
                        target: w,
                        type: '+',
                        idx_arr: [item_key, 'participant_option', j],
                      })
                    }
                    limit={limit || error_message}
                    type="add"
                    small
                  >
                    Add
                  </ButtonIncrement>
                </div>
              </div>
            );
          })}
        </div>
        {limit && !error_message ? (
          <div className="warning-message">{t('cart.unchangeable')}</div>
        ) : null}
        <div className="sum">
          {auto_discount ? (
            <>
              <div className="sum-row original">
                <div className="label">{t('cart.price')}</div>
                <div className="value notranslate">
                  <Price
                    price={total_price_pre}
                    from_currency={currency.tr_currency}
                    to_currency={currency.tr_currency}
                    use_symbol
                  />
                </div>
              </div>
              <div className="sum-row discount">
                <div className="label">{auto_discount}% OFF</div>
                <div className="value notranslate">
                  <Price
                    price={discount}
                    from_currency={currency.tr_currency}
                    to_currency={currency.tr_currency}
                    use_symbol
                  />
                </div>
              </div>
            </>
          ) : null}
          <div className="sum-row selling">
            {!auto_discount ? <div className="label">{t('cart.price')}</div> : null}
            <div className="value notranslate">
              <Price
                price={total_price}
                from_currency={currency.tr_currency}
                to_currency={currency.tr_currency}
                use_symbol
                force_show
              />
            </div>
          </div>
        </div>
      </div>
      {error_message ? <div className="error-message">{parse(error_message)}</div> : null}
    </Wrapper>
  );
};

export default connect(state => state, actions)(ElementCartItem);
