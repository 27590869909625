import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.3);
  ${props => props.transparent && `background-color: transparent;`}
  ${props => props.z_index && `z-index: ${props.z_index};`}
  ${props => props.absolute && `position: absolute;`}
  ${props =>
    props.fade_in &&
    css`
      animation: ${fadeIn} 0.3s ease-out;
    `}
`;

interface Props extends React.HTMLAttributes<HTMLElement> {
  transparent?: boolean;
  z_index?: number;
  absolute?: boolean;
  fade_in?: boolean;
}

const BgLayer: React.FC<Props> = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

export default BgLayer;
