const getCustomPrice = (number_output, custom_prices) => {
  let filtered = {};
  const filtered_rules = custom_prices.filter(
    w => w.min_participant <= number_output
  );
  if (filtered_rules.length) {
    filtered = filtered_rules[filtered_rules.length - 1];
  } else {
    // if it is under min_participant
    filtered = custom_prices[0];
  }
  return filtered;
};

export default getCustomPrice;
