import styled from 'styled-components';
import theme from '/style/theme';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  font_size: string;
}

const Wrapper = styled.div`
  width: 190px;
  margin: 0 auto;
  text-align: center;
  img {
    display: block;
    margin-bottom: 20px;
  }
`;
const Title = styled.div<Props>`
  font-size: 20px;
  line-height: 1.2;
  color: ${theme.mid_gy};
  font-weight: ${theme.bold};
  ${props =>
    props.font_size &&
    `
    font-size: ${props.font_size}
  `}
`;

const EmptyCart = ({ font_size }: Props) => {
  const { t } = useTranslation('common');
  return (
    <Wrapper>
      <img
        src={require('/public/images/header/cart-empty.png')}
        srcSet={`
      ${require('/public/images/header/cart-empty.png')} 1x,
      ${require('/public/images/header/cart-empty@2x.png')} 2x,
      ${require('/public/images/header/cart-empty@3x.png')} 3x
      `}
        alt=""
      />
      <Title font_size={font_size}>{t('cart.empty')}</Title>
    </Wrapper>
  );
};

export default EmptyCart;
