import styled from 'styled-components';
import { transition } from '../../style/mixin';
import theme from '../../style/theme';
import React from "react";
import useTranslation from "next-translate/useTranslation";

const Wrapper = styled.a`
    cursor: pointer;
    height: 30px;
    padding: 0 10px;
    text-align: center;
    font-size: 15px;
    line-height: 28px;
    text-decoration: none;
    border-radius: 20px;
    border: 1px solid ${theme.very_light_gy};
    color: ${theme.mid_gy};
    background-color: #fff;
    float: right;
    ${transition()}
    @media ${theme.lg} {
      height: 23px;
      font-size: 15px;
      line-height: 21px;
    }
  `;

const OtherCountriesButton = ({...rest}) => {
  const { t } = useTranslation('common');
  return (<Wrapper {...rest}>
    <span>{t('other-countries')}</span>
  </Wrapper>);
};

export default OtherCountriesButton;
