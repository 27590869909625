import styled from 'styled-components';
import Link from 'next/link';
import theme from '/style/theme';
import useTranslation from 'next-translate/useTranslation';

const Wrapper = styled.div`
  margin-top 40px;
  text-align: center;
  .mobile-show {
    display: none;
  }
  @media ${theme.xs_max} {
    .mobile-show {
      display: block;
    }
  }
  a {
    position: relative;
    display: inline-block;
    padding: 0 15px;
    font-size: 13px;
    line-height: 1.2;
    color: ${theme.gy};
    font-weight: ${theme.bold};
    &.last {
      &:before {
        display: none;
      }
    }
    @media ${theme.xs_max} {
      &:first-child {
        display: block;
        margin-bottom: 5px;
        &:before {
          display: none;
        }
      }
      &.mobile-last{
        margin-bottom: 5px;
        &:before {
          display: none;
        }
      }
    }
    &:before {
      content: '|';
      position: absolute;
      top: 0;
      right: 0;
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
    
  }
`;

const MenuFooter = () => {
  const { t } = useTranslation('common');
  return (
    <Wrapper>
      <Link href="/main/how_to_trazy">
        <a>{t('how-trazy-works')}</a>
      </Link>
      <Link href="/about-us">
        <a>{t('about-us')}</a>
      </Link>
      <Link href="/main/footer/faq">
        <a>{t('faq')}</a>
      </Link>
      <Link href="/main/footer/careers">
        <a className="last mobile-last" href="/main/footer/careers">
          {t('careers')}
        </a>
      </Link>
      <br />
      <Link href="/main/footer/privacypolicy">
        <a>{t('privacy-policy')}</a>
      </Link>
      <Link href="/main/footer/termsofservice">
        <a className="mobile-last">{t('tos')}</a>
      </Link>
      <br className="mobile-show" />
      <Link href="/main/footer/partnership">
        <a>
          {t('advertisement-partnership')}
        </a>
      </Link>
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="http://blog.trazy.com/"
      >
        {t('blog')}
      </a>
    </Wrapper>
  );
};

export default MenuFooter;
