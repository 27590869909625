import { useState } from 'react';
import styled from 'styled-components';
import ChevronButton from '/components/ui/ChevronButton';
import BgLayer from '/components/ui/BgLayer';
import { connect } from 'react-redux';
import actions from '/store/actions';
import theme from '/style/theme';
import LanguageMenu from './LanguageMenu';
import {useRouter} from "next/router";

const Language = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: top;
`;

const LanguageChanger = ({ language }) => {
  const [open, toggleOpen] = useState(false);
  const { locale } = useRouter();
  const cur_language = language?.data?.length ? language.data.find(x => x.locale == locale) : null;
  return (
    <Language>
      <ChevronButton
        className={`notranslate`}
        active={open}
        onClick={() => toggleOpen(!open)}
        active_color={theme.brand}
        style={{ paddingRight: 0 }}
      >
        {cur_language ? cur_language.name : 'English' }
      </ChevronButton>
      {open ? (
        <>
          <BgLayer onClick={() => toggleOpen(false)} transparent />
          <LanguageMenu toggleOpen={toggleOpen} />
        </>
      ) : null}
    </Language>
  );
};

export default connect(state => state, actions)(LanguageChanger);
