import { Component } from 'react';
import Button from '/components/ui/Button';
import Textarea from '/components/ui/Textarea';
import InputGroup from '/components/ui/InputGroup';
import ButtonGroup from '/components/ui/ButtonGroup';
import { connect } from 'react-redux';
import actions from '/store/actions';
import Label from '/components/ui/Label';
import Rating from '/components/ui/Rating';
import Caption from '/components/ui/Caption';
import Row from '/components/ui/Row';
import Col from '/components/ui/Col';
import Dropzone from '/components/ui/Dropzone';
import axios from 'axios';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
import Select from '/components/ui/Select';
import styled from 'styled-components';
import theme from '/style/theme';
import getUrlParameter from '../../utils/getUrlParameter';
import withTranslation from 'next-translate/withTranslation';

const TextAreaWrapper = styled.div`
  position: relative;
`;

const TextCounter = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: ${theme.gy};
  font-size: 14px;
`;

class ReviewPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      files: [],
      text: '',
      selected_date: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.setFiles = this.setFiles.bind(this);
    this.removeFileByIndex = this.removeFileByIndex.bind(this);
  }
  componentDidMount() {
    const { date, date_options } = this.props;
    if (!date && date_options && date_options.length == 1) {
      this.setState({ selected_date: date_options[0] });
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    const { text, rating, selected_date, files } = this.state;
    const {
      authentication,
      changeSweetAlert,
      activity_id,
      date,
      changeLoading,
      email: decrypted_email
    } = this.props;
    const { t } = this.props.i18n;
    // if has date from parent use it, if not use the selected one
    let reserve_date = date ? date : selected_date.value;
    if (!reserve_date) {
      changeSweetAlert('Please select your booking for review.');
      return;
    }
    if (!rating) {
      changeSweetAlert('Please rate the activity.');
      return;
    }
    changeLoading(true);
    let email = getUrlParameter('user');
    if (decrypted_email) {
      email = decrypted_email;
    }
    let config = {};
    if (authentication.user) {
      config = { headers: { Authorization: 'bearer ' + authentication.token } };
      if (!email) {
        email = authentication.user.email;
      }
    }
    const form = new FormData();
    // TODO - profile_file
    form.append('text', text);
    form.append('rating', rating);
    form.append('email', email);
    form.append('reserve_date', reserve_date);
    if (files.length) {
      files.forEach(v => {
        form.append('review_pic_list', v);
      });
    }
    axios
      .post(
        `${publicRuntimeConfig.API_PATH}/activities/${activity_id}/review`,
        form,
        config
      )
      .then(res => {
        const { onReviewSuccess } = this.props;
        onReviewSuccess();
      })
      .catch(err => {
        changeLoading(false);
        console.log(err);
        const response = err.response;
        if (response) {
          if (response.data) {
            if (response.data.detail) {
              const detail = response.data.detail;
              // if (detail === "no reservation.")
              if (detail === 'already written a review.') {
                changeSweetAlert(t('reviewed'));
                return;
              } else if (detail === 'This format is not supported.') {
                changeSweetAlert(t('format-unsupported'));
                return;
              } else {
                changeSweetAlert(
                  t('review-failed')
                );
                return;
              }
            }
          }
        } else {
          changeSweetAlert(
            t('review-failed')
          );
          return;
        }
      });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  setFiles(files) {
    this.setState({ files });
  }
  removeFileByIndex(index) {
    let _files = Object.assign([], this.state.files);
    _files.splice(index, 1);
    this.setState({
      files: _files,
    });
  }
  handleSelectChange(selected_date) {
    this.setState({
      selected_date,
    });
  }
  render() {
    const { changeModalMember, date, date_options } = this.props;
    const { selected_date, text, rating, files } = this.state;
    const { t } = this.props.i18n;
    return (
      <form onSubmit={this.handleSubmit} noValidate className="form-login">
        {!date ? (
          <InputGroup style={{ marginBottom: '30px' }}>
            <Select
              options={date_options}
              onChange={this.handleSelectChange}
              placeholder={t('popup.select-booking')}
              value={
                selected_date
                  ? selected_date
                  : date_options.length == 1
                  ? date_options[0]
                  : null
              }
              isSearchable={false}
            />
          </InputGroup>
        ) : null}
        <InputGroup style={{ marginBottom: '30px' }}>
          <Label htmlFor="text">{t('popup.rate-activity')}</Label>
          <Rating
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              textAlign: 'right',
            }}
            initialRating={rating}
            onChange={rate => this.setState({ rating: rate })}
          />
          <TextAreaWrapper>
            <Textarea
              id="text"
              name="text"
              value={text}
              onChange={this.handleInputChange}
              className="textarea"
              style={{
                height: '140px',
              }}
              maxLength={5000}
            />
            <TextCounter>{text.length}/5000</TextCounter>
          </TextAreaWrapper>
          <Caption style={{ color: '#999' }}>
            {t('popup.write-and-earn')}
          </Caption>
        </InputGroup>
        <InputGroup>
          <Label htmlFor="">{t('popup.up-to')}</Label>
          <Dropzone
            files={files}
            setFiles={this.setFiles}
            removeFileByIndex={this.removeFileByIndex}
          />
        </InputGroup>
        <ButtonGroup
        // right
        >
          <Row style={{ textAlign: 'right' }}>
            <Col desktop_width="145px">
              <Button
                lightgray
                // width="145px"
                full
                onClick={() =>
                  changeModalMember({
                    show: false,
                  })
                }
              >
                {t('popup.cancel')}
              </Button>
            </Col>
            <Col desktop_width="130px">
              <Button
                // width="130px"
                submit
                disabled={this.props.uistatus.submitting}
                full
              >
                {t('popup.post')}
              </Button>
            </Col>
          </Row>
        </ButtonGroup>
      </form>
    );
  }
}

export default connect(state => state, actions)(withTranslation(ReviewPopup, 'common'));
