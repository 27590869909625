import { Component } from 'react';
import styled from 'styled-components';
import Button from '/components/ui/Button';
import Textbox from '/components/ui/Textbox';
import Or from './Or';
import OtherLink from './OtherLink';
import InputGroup from '/components/ui/InputGroup';
import ButtonGroup from '/components/ui/ButtonGroup';
import Caption from '/components/ui/Caption';
import theme from '/style/theme';
import { connect } from 'react-redux';
import actions from '/store/actions';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import getConfig from 'next/config';
import { transition } from '/style/mixin';
import withTranslation from 'next-translate/withTranslation';
import Trans from "next-translate/Trans";

const { publicRuntimeConfig } = getConfig();

const ForgotPassword = styled.span`
  float: right;
  color: ${props => props.theme.brand};
  font-size: 12px;
  line-height: 13px;
  cursor: pointer;
  ${transition()}
  &:hover {
    color: ${theme.brandsub};
  }
`;

const FbWrap = styled.div`
  margin-top: -75px;
  @media ${theme.sm} {
    margin-top: 0;
  }
`;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    const { email, password } = this.state;
    const { changeSweetAlert, authenticate } = this.props; // from store
    const { t } = this.props.i18n;
    if (!email) {
      changeSweetAlert({
        show: true,
        title: t('popup.validation.empty-email'),
      });
      return;
    }
    if (!password) {
      changeSweetAlert({
        show: true,
        title: t('popup.validation.empty-password'),
      });
      return;
    }
    const { lang } = this.props.i18n;
    authenticate({
      email,
      password,
      locale: lang,
    });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  responseFacebook(res) {
    const { lang } = this.props.i18n;
    this.props.authenticate({ fb_token: res.accessToken, fb_id: res.id, email: res.email, locale: lang });
  }
  render() {
    const { device, subdomain, changeModalMember } = this.props;
    const { t } = this.props.i18n;
    return (
      <form onSubmit={this.handleSubmit} noValidate className="form-login">
        {device !== 'app' && subdomain !== 'business' ? (
          <FbWrap>
            <FacebookLogin
              appId={publicRuntimeConfig.FB_APP_ID}
              callback={this.responseFacebook}
              fields="first_name,last_name,email"
              render={renderProps => (
                <Button full facebook onClick={renderProps.onClick}>
                  {t('popup.login-with-fb')}
                </Button>
              )}
            />
            <Or />
          </FbWrap>
        ) : null}
        <InputGroup>
          <Textbox
            id="email"
            name="email"
            placeholder={t('popup.form.email')}
            autoComplete="username"
            value={this.state.email}
            onChange={this.handleInputChange}
          />
        </InputGroup>
        <InputGroup>
          <Textbox
            can_switch_type
            id="password"
            name="password"
            placeholder={t('popup.form.password')}
            autoComplete="current-password"
            value={this.state.password}
            onChange={this.handleInputChange}
          />
          <Caption style={{ textAlign: 'left', height: '1.2' }}>
            <ForgotPassword
              onClick={() => {
                changeModalMember({
                  show: true,
                  type: 'password',
                });
              }}
            >
              {t('popup.forgot-password')}
            </ForgotPassword>
          </Caption>
        </InputGroup>
        <ButtonGroup>
          <Button full submit gradient>
            {t('login')}
          </Button>
          {subdomain !== 'business' ? (
            <OtherLink>
              <Trans
                i18nKey="common:popup.no-account"
                components={[
                  <strong
                    onClick={() => {
                      changeModalMember({
                        show: true,
                        type: 'signup',
                      });
                    }}
                  >
                  </strong>
                ]}
              >
              </Trans>
            </OtherLink>
          ) : null}
        </ButtonGroup>
      </form>
    );
  }
}

export default connect(
  state => ({
    subdomain: state.subdomain,
    device: state.device,
  }),
  {
    changeModalMember: actions.changeModalMember,
    changeSweetAlert: actions.changeSweetAlert,
    authenticate: actions.authenticate,
  },
)(withTranslation(Login, 'common'));
