import round from './roundLikePHP';
import convertPrice from './convertPrice';
import getCustomPrice from './getCustomPrice';
import { Map } from 'immutable';

const getSum = ({
  option_map = Map({}),
  currency = {},
  custom_currency = '',
  auto_discount = 0,
} = {}) => {
  let total_price_pre = 0;
  let total_price = 0;
  let to_currency = currency.tr_currency;
  if (custom_currency) {
    to_currency = custom_currency;
  }
  if (!option_map.size) return total_price;
  option_map.valueSeq().forEach(v => {
    // iterate id+date+time values
    if (v.get('participant_option')) {
      v.get('participant_option')
        .valueSeq()
        .forEach(x => {
          // iterate participants
          // sum only numbers in here
          // because each price can have fixed price
          let price = x.get('price');
          if (x.get('custom_prices') && x.get('custom_prices').size) {
            const filtered = getCustomPrice(
              x.get('qty'),
              x.get('custom_prices').toJS()
            );
            price = filtered.price;
          }
          const fixed_price_setting = x.get('fixed_price_setting').toJS();
          const price_converted = convertPrice({
            price,
            from_currency: x.get('price_currency'),
            to_currency,
            use_symbol: false,
            use_format_money: false,
            fixed_price_setting,
            currency_info: currency.data,
          });
          total_price_pre += price_converted * x.get('qty');
          if (!auto_discount) {
            total_price = total_price_pre;
          } else {
            const price_converted = convertPrice({
              price: x.get('price'),
              from_currency: x.get('price_currency'),
              to_currency,
              use_symbol: false,
              use_format_money: false,
              fixed_price_setting,
              currency_info: currency.data,
            });
            const price_discounted = round(
              Number(price_converted) * (1 - auto_discount / 100),
              2
            );
            total_price += price_discounted * x.get('qty');
          }
        });
    }
  });
  return {
    converted: parseFloat(total_price.toFixed(2)),
    pre_converted: parseFloat(total_price_pre.toFixed(2)),
  };
};

export default getSum;
