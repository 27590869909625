// @ts-nocheck
import React from 'react';
import mobiscroll from '@mobiscroll/react';

interface Props {}

const MobiscrollBirth = React.memo(({ birth, years, handleOnSet }: Props) => {
  return (
    <mobiscroll.Select
      display="center"
      placeholder="YYYY"
      cssClass="mbsc-mint"
      inputClass="mint"
      data={years}
      value={birth}
      onSet={handleOnSet}
      onItemTap={(e, inst) => {
        if (e.selected) {
          inst.select();
        }
      }}
      onBeforeShow={(e, inst) => {
        if (!birth) {
          inst.setVal(1995);
        }
      }}
      onCancel={(e, inst) => {
        inst.setVal(birth);
      }}
    />
  );
});

export default MobiscrollBirth;
