import styled from "styled-components";
import useTranslation from 'next-translate/useTranslation';

const Wrapper = styled.div`
  margin: 19px 0;
  font-weight: ${props => props.theme.normal};
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  color: ${props => props.theme.gy};
`;
const Or = () => {
  const { t } = useTranslation('common');
  return (
    <Wrapper>{t('popup.or')}</Wrapper>
  )
}

export default Or;
