import roundLikePHP from './roundLikePHP';

const getFixedPriceCurrency = (to_currency, settings) => {
  let output = 0;
  if (to_currency && settings) {
    output = settings[to_currency] ? settings[to_currency] : 0;
  }
  return output;
};

const getCurrencyObj = (str, currency_info) => {
  const output = currency_info.filter(v => {
    return v.currency_name === str;
  });
  return output[0];
};

const trimTrailingChars = (s, charToTrim) => {
  const regExp = new RegExp(charToTrim + '+$');
  const result = s.replace(regExp, '');
  return result;
};

const formatMoney = (n, c, d, t) => {
  c = isNaN((c = Math.abs(c))) ? 2 : c;
  d = d == undefined ? '.' : d;
  t = t == undefined ? ',' : t;
  let s = n < 0 ? '-' : '';
  let i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + '';
  let j = i.length;
  j = j > 3 ? j % 3 : 0;
  if (n % 1 == 0) {
    c = 0;
  }
  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c
      ? d +
        trimTrailingChars(
          Math.abs(n - i)
            .toFixed(c)
            .slice(2),
          '0'
        )
      : '')
  );
};

const convertPrice = ({
  price = 0,
  from_currency = 'USD',
  to_currency = 'USD',
  use_symbol = true,
  use_format_money = true,
  force_show = false,
  fixed_price_setting = {},
  ceil_increment = false,
  currency_info = [],
  discount_convert = false,
} = {}) => {
  let fixed_price_currency = getFixedPriceCurrency(
    to_currency,
    fixed_price_setting
  );
  let convert_price = '';
  if (
    typeof from_currency !== 'undefined' &&
    typeof to_currency !== 'undefined' &&
    currency_info.length
  ) {
    if (!from_currency) {
      from_currency = 'USD';
    }
    let is_minus = false;
    if (price != 0 || force_show) {
      convert_price = parseFloat(price);
      if (price < 0) {
        is_minus = true;
      }
      const from_currency_obj = getCurrencyObj(from_currency, currency_info);
      const to_currency_obj = getCurrencyObj(to_currency, currency_info);
      if (fixed_price_currency > 0) {
        convert_price = fixed_price_currency;
      } else if (from_currency !== to_currency) {
        convert_price =
          (price / from_currency_obj.currency_exchange) *
          to_currency_obj.currency_exchange;
        let precision = to_currency_obj.num_precision;
        if (discount_convert) {
          convert_price = roundLikePHP(convert_price, to_currency === 'KRW' ? 0 : 2);
        } else {
          if (precision > 0) {
            if (precision >= 1) {
              convert_price = Math.floor(convert_price / precision) * precision;
            } else {
              let factor = 1 / precision;
              convert_price = Math.floor(convert_price * factor) / factor;
            }
          }
        }
      }
      if (ceil_increment) {
        let increment = to_currency_obj.increment;
        if (increment > 0) {
          if (increment >= 1) {
            convert_price = Math.ceil(convert_price / increment) * increment;
          } else {
            let factor = 1 / increment;
            convert_price = Math.ceil(convert_price * factor) / factor;
          }
        }
      }
      convert_price = Math.abs(convert_price);
      if (use_format_money) {
        convert_price = formatMoney(convert_price, 2);
      }
      if (use_symbol && to_currency_obj) {
        convert_price = to_currency_obj.symbol + ' ' + convert_price;
      }
      if (is_minus) {
        convert_price = '- ' + convert_price;
      }
    }
  }
  return convert_price;
};

export default convertPrice;
