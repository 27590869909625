import { Component } from 'react';
import Modal from 'react-modal';
import Close from '/components/ui/Close';
import styled from 'styled-components';
import { clearfix } from '/style/mixin';
import Login from '/components/Header/Login';
import Signup from '/components/Header/Signup';
import FindPassword from '/components/Header/FindPassword';
import ReviewPopup from '/components/ui/ReviewPopup';
import SpotReviewPopup from '/components/ui/SpotReviewPopup';
import theme from '/style/theme';
import { connect } from 'react-redux';
import actions from '/store/actions';
import parse from '/utils/parse';
import withTranslation from 'next-translate/withTranslation';

const Wrapper = styled.div`
  overflow: hidden;
  font-weight: ${theme.normal};
  background-color: ${theme.light_og};
  border-radius: 5px;
  line-height: 1;
  ${props =>
    props.mint &&
    `
    background-color: ${theme.trazy_mint};
  `}
  ${props =>
    props.alt_for_mobile &&
    `
    margin: 0;
    border-radius: 0;
    @media ${theme.sm} {
      border-radius: 5px;
    }
    .form-section {
      position: fixed;
      z-index: 400;
      top: 50px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;
      padding: 0;
      background-color: ${theme.light_gy1};
      @media ${theme.sm} {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        overflow-y: hidden;
        padding: 0;
        /*padding: 60px 75px 0 72px;*/
        background-color: transparent;
      }
    }
    .desc-section {
      height: ${props.type === 'review' ? '100px' : '50px'}
      padding: ${props.type === 'review' ? '16px 0 0' : '16px'}
      text-align: center;
      ${props.mint} {
        background-color: ${theme.trazy_mint};
      }
      @media ${theme.sm} {
        top: auto;
        left: auto;
        right: auto;
        background-color: transparent;
        padding: ${
          props.type === 'spot_review' ? '50px 30px 100px' : '95px 30px 100px'
        };
        text-align: left;
      }
      .title {
        font-size: 15px;
        @media ${theme.sm} {
          font-size: ${props.type === 'spot_review' ? '25px' : '30px'};
        }
      }
      .description {
        @media ${theme.xs_max} {
          height: 50px;
          font-size: 14px;
          color: #666;
          letter-spacing: -.2px;
          text-align: center;
          line-height: 23px;
          margin-top: 16px;
          padding-top: 14px;
          background-color: ${theme.light_gy2};
          br {
            display: none;
          }
        }
      }
    }
  `}
  .modal-wrap {
    position: relative;
    ${clearfix}
  }
`;
const FormSection = styled.div`
  padding: 50px 38px;
  background-color: #fff;
  font-size: 14px;
  color: ${theme.mid_gy};
  @media ${theme.sm} {
    float: left;
    width: calc(100% - 245px);
    padding: 100px 35px 100px 45px;
  }
  ${props =>
    (props.type === 'review' || props.type === 'spot_review') &&
    `
    padding: 30px;
    @media ${theme.sm} {
      padding: 30px;
    }
  `}
  ${props =>
    props.type === 'signup2' &&
    `
    padding: 0;
    @media ${theme.sm} {
      padding: 0;
    }
  `}
  ${props =>
    props.type === 'spot_review' &&
    `
    .enjoy-label {
      display: block;
      @media ${theme.sm} {
        display: inline-block;
        padding-top: 11px;
        margin-right: 27px;
        vertical-align: bottom;
      }
    }
    .enjoy-wrapper {
      display: block;
      @media ${theme.sm} {
        display: inline-block;
        vertical-align: bottom;
      }
    }
  `}
`;
const DescSection = styled.div`
  padding: 40px 38px 59px;
  text-align: left;
  color: #fff;
  ${props =>
    props.hide_desktop &&
    `
    padding-top: 89px;
  `}
  @media ${theme.sm} {
    height: auto;
    float: right;
    width: 245px;
    padding: 95px 30px 100px;
  }
  .title {
    font-size: 40px;
    font-weight: ${theme.bold};
    color: #fff;
    line-height: 1.2;
    margin: 0;
    white-space: nowrap;
    @media ${theme.sm} {
      font-size: 30px;
    }
  }
  .description {
    margin: 13px 0 0;
    line-height: 1.64;
    color: #fff;
    ${props =>
      props.hide_desktop &&
      `
      @media ${theme.sm} {
        display: none;
      }
    `}
  }
  .brand {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 245px;
    padding: 10px;
    background-color: ${theme.mid_gy};
    border-radius: 0 0 5px 0;
    ${clearfix}
    @media ${theme.sm} {
      display: block;
    }
    img {
      display: block;
      float: right;
    }
  }
`;
const MobileUI = styled.div`
  .close-button {
    top: 6px;
    right: 6px;
    @media ${theme.sm} {
      display: none;
    }
  }
`;
const Back = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background: url(${require('../../public/images/ui/chevron-left-white.svg')})
    #5ebebe no-repeat center center;
`;
const Welcome = styled.div`
  position: relative;
  background-color: ${theme.light_mint3};
  border-radius: 5px;
  overflow: hidden;
  .title {
    padding: 50px 20px;
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-weight: ${theme.bold};
    line-height: 1.33;
  }
`;
const WelcomeImg = styled.div`
  background: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/popup/img_welcome.png')
    no-repeat center center;
  height: 266px;
`;
const AffiliateReg = styled.div`
  position: relative;
  background-color: #f3f3f3;
  border-radius: 2px;
  padding: 30px 0;
  text-align: center;
  color: #464646;
  .title {
    margin-bottom: 5px;
    font-size: 22px;
    line-height: 25px;
    font-weight: 700;
  }
  .subtitle {
    font-size: 12px;
  }
  .content {
    max-width: 305px;
    margin: 30px auto 0;
    text-align: left;
    .sub_content {
      &:nth-child(1) {
        margin-bottom: 30px;
      }
      .intro {
        display: inline-block;
        width: 200px;
        line-height: 32px;
        vertical-align: bottom;
        font-size: 18px;
      }
      .btn {
        display: inline-block;
        height: 32px;
        line-height: 32px;
        width: 100px;
        font-size: 16px;
        background-color: #f05634;
        color: #fff;
        font-weight: 500;
        border: 1px solid #f05634;
        padding: 0 5px;
        text-align: center;
        cursor: pointer;
        border-radius: 2px;
      }
    }
  }
`;
Modal.setAppElement('#__next');

class ModalMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      maxWidth: '660px',
    };
    this.switchType = this.switchType.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  switchType(type) {
    const { t } = this.props.i18n;
    if (type === 'login') {
      this.setState({
        title: t('login'),
        description:
          t('popup.login-description'),
        maxWidth: '660px',
      });
    } else if (type === 'signup') {
      this.setState({
        title: t('signup'),
        description:
          t('popup.signup-description'),
        maxWidth: '660px',
      });
    } else if (type === 'signup2') {
      this.setState({
        title: t('signup'),
        description: '',
        modalPadding: '0',
        maxWidth: '768px',
      });
    } else if (type === 'password') {
      this.setState({
        title: t('popup.reset-password'),
        description: t('popup.reset-description'),
        maxWidth: '660px',
      });
    } else if (type === 'welcome') {
      this.setState({
        maxWidth: '395px',
      });
    } else if (type === 'review') {
      this.setState({
        title: t('popup.review'),
        description: t('popup.review-description'),
        isFull: false,
        maxWidth: '786px',
      });
    } else if (type === 'spot_review') {
      this.setState({
        title: t('popup.review'),
        description: '',
        isFull: false,
        maxWidth: '786px',
      });
    } else if (type === 'affiliate') {
      this.setState({
        maxWidth: '500px',
      });
    }
  }
  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      this.switchType(data.type);
    }
  }
  componentDidMount() {
    this.switchType(this.props.data.type);
  }
  componentWillUnmount() {
    this.handleCloseModal();
  }
  handleCloseModal() {
    this.props.changeModalMember({ show: false, data: null });
  }
  render() {
    const { data, uistatus } = this.props;
    const { maxWidth, title, description } = this.state;
    const { t } = this.props.i18n;
    return (
      <Modal
        isOpen={data.show}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnOverlayClick={false}
        className={
          data.type === 'signup2' ||
          data.type === 'review' ||
          data.type === 'spot_review'
            ? 'react-modal-content-full'
            : 'react-modal-content'
        }
        style={{
          content: {
            outline: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflowX: 'hidden',
            padding: '20px',
            backgroundColor: 'transparent',
            border: 0,
            maxWidth: maxWidth,
            width: '100%',
          },
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.3)',
            zIndex: 200,
          },
        }}
      >
        {data.type === 'welcome' ? (
          <Welcome>
            <Close
              className="close-button"
              backgroundColor={theme.darker_gray}
              onClick={() => {
                this.props.changeModalMember({
                  show: false,
                });
              }}
            />
            <WelcomeImg />
            <div className="title">{t('popup.welcome')}</div>
          </Welcome>
        ) : data.type === 'affiliate' ? (
          <AffiliateReg>
            <Close
              backgroundColor={theme.trazy_og}
              className="button"
              onClick={() => {
                this.props.changeModalMember({
                  show: false,
                  data: null,
                });
              }}
            />
            <div className="title">
              {t('popup.affiliate.welcome')}
              <br />
              {t('popup.affiliate.join')}
            </div>
            <div className="subtitle">
              {t('popup.affiliate.need-account')}
            </div>
            <div className="content">
              <div className="sub_content">
                <div className="intro">{t('popup.affiliate.new')}</div>
                <div
                  className="btn"
                  onClick={() => {
                    this.props.changeModalMember({
                      show: true,
                      type: 'signup',
                    });
                  }}
                >
                  {t('signup')}
                </div>
              </div>
              <div className="sub_content">
                <div className="intro">{t('popup.affiliate.member')}</div>
                <div
                  className="btn"
                  onClick={() => {
                    this.props.changeModalMember({
                      show: true,
                      type: 'login',
                    });
                  }}
                >
                  {t('login')}
                </div>
              </div>
            </div>
          </AffiliateReg>
        ) : (
          // TODO - separate each type as components
          <Wrapper
            mint={data.type === 'signup' || data.type === 'signup2'}
            alt_for_mobile={
              data.type === 'signup2' ||
              data.type === 'review' ||
              data.type === 'spot_review'
            }
            type={data.type}
          >
            <div className="modal-wrap">
              <DescSection
                className="desc-section"
                hide_desktop={data.type === 'password'}
              >
                <div className="title">{parse(title)}</div>
                <p
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
                <div className="brand">
                  <img
                    src={require('/public/images/header/modal-brand.png')}
                    srcSet={`
                      ${require('/public/images/header/modal-brand.png')} 1x,
                      ${require('/public/images/header/modal-brand@2x.png')} 2x,
                      ${require('/public/images/header/modal-brand@3x.png')} 3x
                  `}
                    alt="TRAZY"
                  />
                </div>
                {(data.type === 'signup2' || data.type === 'review') &&
                uistatus.screenwidth === 'xs' ? (
                  // add mobile header for signup2 state
                  <MobileUI>
                    {data.type === 'signup2' ? (
                      <Back
                        onClick={() => {
                          this.props.changeModalMember({
                            show: true,
                            type: 'signup',
                          });
                        }}
                      />
                    ) : null}
                    <Close
                      className="close-button"
                      onClick={() => {
                        this.props.changeModalMember({
                          show: false,
                          data: null,
                        });
                      }}
                    />
                  </MobileUI>
                ) : (
                  <Close
                    className="button"
                    onClick={() => {
                      this.props.changeModalMember({
                        show: false,
                        data: null,
                      });
                    }}
                  />
                )}
              </DescSection>
              <FormSection type={data.type}>
                {data.type === 'login' ? (
                  <Login />
                ) : data.type === 'signup' || data.type === 'signup2' ? (
                  <Signup type={data.type} />
                ) : data.type === 'password' ? (
                  <FindPassword description={description} />
                ) : data.type === 'review' ? (
                  <ReviewPopup
                    activity_id={data.data.activity_id}
                    date={data.data.date}
                    date_options={data.data.date_options}
                    onReviewSuccess={data.data.onReviewSuccess}
                    email={data.data.email}
                  />
                ) : data.type === 'spot_review' ? (
                  <SpotReviewPopup
                    spot_id={data.data.spot_id}
                    onReviewSuccess={data.data.onReviewSuccess}
                  />
                ) : null}
              </FormSection>
            </div>
          </Wrapper>
        )}
      </Modal>
    );
  }
}

export default connect(state => state, actions)(withTranslation(ModalMember, 'common'));
