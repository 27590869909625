import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 36px;
  height: 36px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/default/profile.png');
  border-radius: 100px;
  ${props =>
    props.width &&
    `
        width: ${props.width}px;
        height: ${props.width}px;
    `}
  ${props =>
    props.src &&
    `
        background-image: url(${props.src});
    `}
`;

const Profile = ({ src, width, ...rest }) => {
  return <Wrapper {...rest} width={width} src={src} />;
};

Profile.propTypes = {
  width: PropTypes.number,
  src: PropTypes.node,
};

export default Profile;
