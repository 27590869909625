import styled from 'styled-components';
import { clearfix } from '/style/mixin';
import theme from '/style/theme';

const Wrapper = styled.div`
position: relative;
margin-top: 10px;
${clearfix}
${props =>
  props.no_margin &&
  `
  margin-top: 0;
`}
${props =>
  props.multi_column &&
  `
  margin-left: -3px;
  margin-right: -3px;
`}
@media ${theme.xs_max} {
  ${props =>
    props.xs_margin_top &&
    `
    margin-top: ${props.xs_margin_top};
  `}
}
&:first-child {
  margin-top: 0;
}
`;

const InputGroup = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

export default InputGroup;
